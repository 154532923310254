<template>
  <v-card>
    <v-toolbar elevation="0">
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Cadastro de clientes</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <div>
          <v-form
            class="p-lg-12 row"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <div class="col-lg-12 form-group clearfix mb-3">
              <v-text-field
                label="Nome"
                v-model="clienteEdit.nome"
                :rules="validationNome"
                clear-icon="clear"
                required
                :type="'text'"
                :outlined="false"
                :disabled="show"
              />
            </div>

            <div class="col-lg-12 form-group clearfix mb-3">
              <v-text-field
                label="E-mail"
                v-model="clienteEdit.email"
                :rules="validationEmail"
                clear-icon="clear"
                required
                :type="'email'"
                :outlined="false"
                :disabled="show"
              />
            </div>

            <!-- <div class="col-lg-12 form-group clearfix mb-3">
              <v-text-field
                label="Senha"
                v-model="clienteEdit.senha"
                clear-icon="clear"
                :rules="validationSenha"
                required
                :type="'password'"
                :outlined="false"
                :disabled="show"
              />
            </div> -->

            <v-col cols="12">
              <v-text-field
                v-model="clienteEdit.cpf"
                :rules="validationCpf"
                label="CPF"
                required
                v-mask="'###.###.###-##'"
                :disabled="show"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="clienteEdit.data_nascimento"
                :type="'date'"
                required
                :rules="validationNascimento"
                label="Data de nascimento"
                :disabled="show"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="clienteEdit.telefone_1"
                label="Telefone 1"
                :rules="validationTelefone"
                v-mask="'(##) #####-####'"
                required
                :disabled="show"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="clienteEdit.telefone_2"
                label="Telefone 2"
                v-mask="'(##) #####-####'"
                :disabled="show"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <p>Gênero</p>
              <v-radio-group
                :rules="validationGenero"
                v-model="clienteEdit.genero"
                column
                required
                :disabled="show"
              >
                <v-radio label="Masculino" value="M"></v-radio>
                <v-radio label="Feminino" value="F"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :disabled="show"
                :rules="validationCep"
                v-model="clienteEdit.cep"
                @change="buscaCep"
                label="CEP"
                required
                v-mask="'##.###-###'"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :disabled="show"
                :rules="validationLogradouro"
                v-model="clienteEdit.logradouro"
                label="Logradouro"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :disabled="show"
                :rules="validationNumero"
                v-model="clienteEdit.numero"
                label="Número"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :disabled="show"
                :rules="validationBairro"
                v-model="clienteEdit.bairro"
                label="Bairro"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                required
                :disabled="show"
                :rules="validationCidade"
                v-model="clienteEdit.cidade"
                label="Cidade"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :disabled="show"
                :rules="validationEstado"
                v-model="clienteEdit.estado"
                label="Estado"
                required
              ></v-text-field>
            </v-col>

            <!-- BOTÃO SUBMIT FORMULÁRIO -->
            <div v-show="!show">
              <v-btn color="#3E682A" text @click="$emit('close')">
                Cancelar
              </v-btn>
              <v-btn color="#3E682A" text @click="update" :loading="loading">
                <span>Atualizar cliente</span>
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import ClienteService from "@/services/cliente_service.js";
import ValidationService from "@/services/validation_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import CEPService from "@/services/cep_service.js";

export default {
  mixins: [VuexSnackbarMixin, VuexUsuarioMixin],

  beforeMount() {
    this.validationService = ValidationService;

    if (this.$props.cliente) {
      this.clienteEdit = this.$props.cliente;
      const regex = / \d\d:\d\d:\d\d/gm;
      const dataFormatada = this.clienteEdit.data_nascimento.replace(regex, "");

      this.clienteEdit.data_nascimento = dataFormatada;
    } else {
      this.clienteEdit = {};
    }
  },

  props: {
    unidades: Array,
    show: Boolean,
    cliente: Object,
  },
  data: () => ({
    valid: false, // Guarda a variável que guarda o estado da validação do formulário
    nome: "",
    senha: "",
    tel_um: "",
    tel_dois: "",
    data_nasc: "",
    validationService: {},
    clienteEdit: {},
    email: "",
    cpf: "",
    rg: "",
    genero: "",
    cep: "",
    logradouro: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    loading: false,
  }),
  computed: {
    validationNome() {
      return [this.validationService.required("Informe o nome.")];
    },

    validationEmail() {
      return [this.validationService.required("Informe o e-mail.")];
    },

    validationCpf() {
      return [this.validationService.required("Informe o cpf.")];
    },

    validationTelefone() {
      return [this.validationService.required("Informe um telefone.")];
    },

    validationSenha() {
      return [this.validationService.required("Informe a senha.")];
    },

    validationNascimento() {
      return [this.validationService.required("Informe a data de nascimento.")];
    },

    validationGenero() {
      return [this.validationService.required("Informe o gênero.")];
    },

    validationCep() {
      return [this.validationService.required("Informe o CEP.")];
    },

    validationLogradouro() {
      return [this.validationService.required("Informe o logradouro.")];
    },

    validationNumero() {
      return [this.validationService.required("Informe o número.")];
    },

    validationBairro() {
      return [this.validationService.required("Informe o bairro.")];
    },

    validationCidade() {
      return [this.validationService.required("Informe a cidade.")];
    },

    validationEstado() {
      return [this.validationService.required("Informe o estado.")];
    },
  },
  methods: {
    buscaCep() {
      const cepService = new CEPService();
      this.loading = true;
      cepService.buscaCep(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.clienteEdit.logradouro = body.logradouro;
              this.clienteEdit.estado = body.uf;
              this.clienteEdit.cidade = body.localidade;
              this.clienteEdit.bairro = body.bairro;
              this.clienteEdit.complemento = body.complemento;
            }
          },
          onError: (error) => {
            return error;
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        this.clienteEdit.cep.replace("-", "").replace(".", "")
      );
    },

    update() {
      const criacaoCliente = this.clienteEdit;

      criacaoCliente.data_nascimento = this.clienteEdit.data_nascimento
        .split("/")
        .reverse()
        .join("-");

      criacaoCliente.endereco_id = this.$props.cliente.endereco_id;
      criacaoCliente.perfil_id = this.$props.cliente.perfil_id;
      criacaoCliente.id = this.$props.cliente.id;
      criacaoCliente.pessoa_fisica_id = this.$props.cliente.pessoa_fisica_id;
      criacaoCliente.cliente_id = this.$props.cliente.cliente_id;

      if (!this.$refs.form.validate() || !this.valid) {
        return;
      }
      this.loading = true;
      new ClienteService().update(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.errors[Object.keys(body.errors)[0]],
                color: "error",
              });
            }

            this.$emit("reload");
            this.$emit("close");
          },
          onError: () => {
            // this.$_ACTIONS_showSnackbarMessage({
            //   message: this.$global.messages.internalServerError,
            //   color: "error",
            // });
          },
          onEnd: () => {
            this.loading = false;
            this.$emit("reload");
            this.$emit("close");
          },
        },
        criacaoCliente
      );
    },
  },
};
</script>
