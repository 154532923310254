<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Estoque</h3>
      </div>
    </div>
    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="produtos"
      :options.sync="options"
      :server-items-length="totalList"
      :loading="loading"
      sort-by="descricao"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG CADASTRO -->
          <v-select
            :item-text="'nome_fantasia'"
            :item-value="'id'"
            :items="unidades"
            label="Unidades"
            @change="atualizarEstoque"
            v-model="unidade_id"
          ></v-select>

          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          /> -->
          
          <v-dialog
            v-if="dialogEdicao"
            v-model="dialogEdicao"
            max-width="500px"
          >
            <modal-edicao
              v-if="dialogEdicao"
              :produto="editedProduto"
              :unidade_id="unidade_id"
              @close="dialogEdicao = false"
              @atualizar="atualizarEstoque"
              @response="atualizarTipoDeProcedimentoPosEdicao($event)"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      
      <template v-slot:item.actions="{ item }">

        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>

      </template>
      
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#981113" dark @click="atualizarEstoque">
          Atualizar
        </v-btn>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import EstoqueService from "@/services/estoque_service.js";
import UnidadesService from "@/services/unidade_service.js";
import modalEdicao from "@/components/estoque/modal_estoque_operacao.vue";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";


export default {
  mixins: [Mixin],
  components: { modalEdicao },
  data: () => ({
    unidade_id: -1,
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    editedProduto: {},
    unidades: [],
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "produto",
      },
      { text: "Quantidade", align: "center", value: "quantidade", sortable: false },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    produtos: [],
    editedIndex: -1,
    editedItem: {
      name: "",
    },
    defaultItem: {
      name: "",
    },
    loading: false,
    messageSnackbar: "",
    colorSnackbar: "",
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo Produto" : "Editar Produto";
    },
  },

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarEstoque();
      },
      deep: true,
    },
    search() {
      this.atualizarEstoque();
    },
  },

  beforeMount() {
    this.atualizarEstoque();
  },

  mounted() {
    this.atualizarUnidades()
  },

  methods: {
    atualizarProdutoCadastro() {
      this.atualizarEstoque();
      this.closeDialogCadastrar();
    },

    atualizarUnidades() {
      this.loading = true
      const unidadeService = new UnidadesService()
      this.unidades=[];
      const onSucess = (status) => (body) => {
        if (status === 200) {
         this.totalList = body.data.pagination.num_rows
         this.unidades = body.data.unidades
         this.unidade_id = this.unidades[0].id;
         this.atualizarEstoque();
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      unidadeService.getAll(onSucess, onError, onEnd,this.options.page, this.options.itemsPerPage, this.search, this.options.ordenacao, this.options.desc);
    },
    
    atualizarEstoque() {
      this.loading = true;
      const estoqueService = new EstoqueService();
      this.loading = true;
      this.produtos = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.totalList = 50;
          this.produtos = body.data.estoque;
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      estoqueService.getAllByUnidade(
        onSucess,
        onError,
        onEnd,
        this.unidade_id,
        this.options.page,
        this.options.itemsPerPage,
        this.search,
        this.options.ordenacao,
        this.options.desc
      );
    },
    editItem(item) {
      console.log(item);
      this.editedProduto = JSON.parse(JSON.stringify(item));
      console.log(this.editedProduto);
      this.dialogEdicao = true;
    },


    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

  },
};
</script>

