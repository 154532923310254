<template>
  <div id="app">
    <v-app>
      <div v-if="estaLogado">
        <v-navigation-drawer class="appBG-primary" v-model="drawer" app>
          <menu-principal />
        </v-navigation-drawer>
        <v-app-bar app>
          <header-pagina @retratil="drawer = !drawer" />
        </v-app-bar>
        <v-main>
          <div class="page-content">
            <div div class="card">
              <div class="card-body">
                <router-view />
              </div>
            </div>
          </div>
        </v-main>
      </div>
      <div v-else>
        <router-view />
      </div>
        <snackbar />
    </v-app>
  </div>
</template>


<script>
// import MenuGeral from "@/components/templates/MenuGeral.vue";
import MenuPrincipal from "@/components/templates/MenuLateral.vue";
import HeaderPagina from "@/components/templates/Header.vue";
import Snackbar from "@/components/widgets/snackbar.vue";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  components: { MenuPrincipal, HeaderPagina, Snackbar },
  mixins: [Mixin],
  mounted() {
    if (!this.estaLogado) {
      this.$_ACTIONS_perfil({ id:1 , description:'adm'})
       if(!this.estaLogado && this.$router.history.current.path !== "/login"){
         this.$router.replace("login")
       }
    }
  },
  data: () => ({
    drawer: null,
  }),
  computed: {
    estaLogado() {
      return this.$_GETTERS_token.length > 0;
    },
  },
};
</script>

<style>
/* App css */
@import "assets/css/bootstrap.min.css";
@import "assets/css/icons.min.css";

/*Plugins css */

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.bg-verde {
  background-color: #981113 !important;
}

.bg-cinza {
  background-color: rgb(239, 239, 239);
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.page-content {
  /* margin-left: 240px; */
  padding: 0 15px;
  /* margin-top: 120px; */
}
.card {
  border: none;
  box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);
  margin-bottom: 30px;
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
}

#side-menu{
  text-decoration: none;
  list-style: none;
  margin-top: 15%;
}
#side-menu li{
  height: 2.5rem;
  font-size: 1rem;
}

.border-primary {
  border: solid 1px #a92429 !important;
}

.appColor-primary {
  color: #a92429 !important;
}

.appColor-secondary {
  color: #959595;
}

.appColor-white {
  color: #fff !important;
}

.footerColor {
  color: #dcdcdc;
}

.appBG-primary {
  background-color: #a92429 !important;
}

.appBG-secondary {
  background-color: #959595;
}

.appBG-white {
  background-color: #fff !important;
}

.footerBG {
  background-color: #dcdcdc;
}
.btn:hover{
  background-color: white!important;
  color: #a92429;
}

</style>

