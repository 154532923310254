import HTTPService from '@/services/http_service.js'

export default class LojaService extends HTTPService {
    resource = 'estoque'
    criarEstoque (responseFunctions, loja) {
        this.jsonRequest(responseFunctions, 'POST', this.resource, loja)
    }

    getList (responseFunctions) {
      this.request(responseFunctions, 'GET', this.resource,null)
    }

    getAllByUnidade (onSucess, onError, onEnd, unidade, page, per_page, filter, order, desc) {
      const responseFunctions = {
          onSucess,
          onError,
          onEnd
      }
      let queryparams ={
        page: page,
        per_page: per_page,
        unidade: unidade,
      }
      if(filter ===''){
        if(order){
          let queryparams ={
            page: page,
            per_page: per_page,
            sort: desc?'nome':'-nome'
          }
          this.request(responseFunctions, 'GET', this.resource,null,queryparams)
        }else{
          this.request(responseFunctions, 'GET', this.resource,null,queryparams)
        }
      }else{
        let queryparams ={
          page: page,
          per_page: per_page,
          nome: filter,
        }
        if(order){
          let queryparams ={
            page: page,
            per_page: per_page,
            nome: filter,
            sort: desc?'nome':'-nome'
          }
          this.request(responseFunctions, 'GET', this.resource,null,queryparams)
        }else{
          this.request(responseFunctions, 'GET', this.resource, null, queryparams)
        }
      }
    }

    deleteLoja ({ onSucess, onError, onEnd },  id ) {
      // ESTÁ SENDO UTILIZADO POST PARA EXCLUSÃO PORQUE A UMBLER INFORMOU ERRO DE CORS AO USAR O MÉTODO DELETE. CORRIGIR. 
        const uri = `${this.resource}/delete/${id}`
        this.request({ onSucess, onError, onEnd }, 'POST', uri)
    }

    updateLoja ({ onSucess, onError, onEnd }, loja) {
        const uri = `${this.resource}/${loja.pacote_id}`
        console.log(uri)
        this.jsonRequest({ onSucess, onError, onEnd }, 'POST', uri, loja)
    }
} 