<template>
  <v-card>
    <v-card-title>
      <span class="headline">Operação no estoque</span> <br>
      <span class="subtitle">produto: {{ produto.produto }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <div>
          <v-form class="p-lg-12 row" ref="form" >
 
          <div class="col-lg-12 form-group clearfix">
            <v-text-field
              label="Quantidade"
              v-model="quantidade"
              clear-icon="clear"
              required
              style="margin: 0 5px; padding-top: 0px !important"
              :type="'number'"
              min="1"
              :outlined="false"
            />
          </div>
            
            <div class="col-lg-12 form-group clearfix">
                <v-select
                  :items="[{name: 'entrada', value: 'entrada'}, {name: 'saída', value: 'saida'}]"
                  required
                  label="Operação"
                  item-text="name"
                  item-value="value"
                  v-model="operacao"
                >
                </v-select>
            </div>

            <div>
              <v-btn color="#3E682A" text @click="$emit('close')">
                Cancelar
              </v-btn>
              <v-btn
                color="#3E682A"
                text
                @click="criarOperacao"
                :loading="loading"
              >
                <span>Fazer entrada/saída</span>
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>

import EstoqueService from "@/services/estoque_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";

export default {
  mixins: [VuexSnackbarMixin, VuexUsuarioMixin],
  beforeMount() {
  },
  props: {
      unidade_id: Number,
      produto: Object,
  },
  data: () => ({
    loading: false, // Guarda o estado do loading de submit
    operacao: "",
    quantidade: 0,
  }),
  computed: {
    
  },
  methods: {
    criarOperacao() {
      const operacaoEstoque = {
        quantidade: this.quantidade,
        operacao: this.operacao,
        unidade_id: this.unidade_id,
        produto_id: this.produto.produto_id,
      };
      
      this.loading = true;
      new EstoqueService().criarEstoque(
        {
          onSucess: (status) => (body) => {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: status === 200 ? "sucess" : "error",
            });
            status === 200 && this.$emit("response");
            this.$refs.form.reset();

            this.loading = false;

            this.$emit("atualizar");
            this.$emit("close");
          },
          onError: () => {
            // this.$_ACTIONS_showSnackbarMessage({
            //   message: this.$global.messages.internalServerError,
            //   color: "error",
            // });
          },
          onEnd: () => {
            
          },
        },
        operacaoEstoque
      );
    },
  },
};
</script>
