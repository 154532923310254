<template>
  <v-card>
    <v-card-title>
      <span class="headline">Cadastro de item na loja</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="nome"
              label="Nome"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.lazy="preco"
              v-money="money"
              v-model="preco"
              label="Preço"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <produto-pacote-item
              v-for="(produto, index) in modal_produtos"
              :index="index"
              :produto="produto"
              :produtos="produtos"
              :adjacent="modal_produtos"
              v-bind:key="index"
              @removeProdutoModal="removeProdutoModal"
            />
          </v-col>
          <v-btn
            @click="
              modal_produtos.push({
                quantidade: 0,
              })
            "
            class="mt-4"
            icon
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-col class="d-flex" cols="6" sm="6">
            <v-radio-group v-model="status" mandatory row>
              <v-radio label="Disponível" color="blue" value="1"></v-radio>
              <v-radio label="Indisponível" color="blue" value="0"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#3E682A" text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn
        color="#3E682A"
        :loading="loading"
        text
        @click="cadastrar"
        :disabled="!nome"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import LojaService from "@/services/loja_service.js";
import Mixin from "@/mixins/vuex_mixin.js";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import ProdutoPacoteItem from "@/components/templates/ProdutoPacoteItem.vue";

const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
});

export default {
  mixins: [Mixin],
  components: {
    ProdutoPacoteItem,
  },
  props: {
    produtos: Array,
  },
  data: () => ({
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    nome: "",
    preco: "",
    status: 0,
    modal_produtos: [{ quantidade: 0 }],
    produto: [],
    pacotes: [],
    search_category: "",
    mask: currencyMask,
    loadingProdutos: false,
    loading: false,
    formCadastro: "",
    error: {
      nome: "",
      preco: "",
      produto: "",
    },
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
      required_arr: (value) => value.length > 0 || "Campo obrigatório.",
    },
  }),

  mounted() {
    this.formCadastro = document.forms[0];
  },

  methods: {
    cadastrar() {
      const makeProduto = (produto) => {
        return {
          produto_id: produto.produto_id,
          quantidade: produto.quantidade,
        };
      };

      const produtos = this.modal_produtos
        .map(makeProduto)
        .filter((produto) => produto.quantidade > 0);

      const camposInvalidosNoProduto = (produto) => {
        return !produto.produto_id && !produto.quantidade;
      };

      if (this.modal_produtos.some(camposInvalidosNoProduto)) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Todos os campos devem ser preenchidos.",
          color: "error",
        });

        return;
      }

      if (this.modal_produtos.some((produto) => produto.quantidade == 0)) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "A quantidade dos produtos deve ser maior que zero.",
          color: "error",
        });

        return;
      }

      const preco = parseFloat(this.preco
        .replace('.', '')
        .replace(' ', '')
        .replace(',', '.')
        .replace('R$', ''))

      const loja = {
        nome: this.nome,
        preco: preco,
        produtos: produtos,
        ativo: this.status,
      };

      const lojaService = new LojaService();

      this.loading = true;
      lojaService.cadastrar(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message || "Item da loja criada com sucesso!",
                color: "sucess",
              });
              this.$emit("response");
            }
          },
          onError: (error) => {
            console.error(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        loja
      );
    },

    removeProdutoModal(index) {
      this.modal_produtos = this.modal_produtos.filter((_, ix) => ix != index);
    },

    //----------------------------------------------------------------------------------------------------------------//

    // ---------------------------------------------------------------------------------------------------------------//

    remover(item) {
      const index = this.produto.indexOf(item.produto_id);
      if (index >= 0) this.produto.splice(index, 1);
    },
  },
};
</script>