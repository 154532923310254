import HTTPService from '@/services/http_service.js'

export default class AgendamentoService extends HTTPService {
    resource = 'agendamento'  
        desmarcarAgendamento(responseFunctions, idAgendamento) {
        this.request(responseFunctions, 'POST', `${this.resource}/${idAgendamento}/desmarcar`)
    }

    register(responseFunctions, agendamento) {
        this.jsonRequest(responseFunctions, 'POST', this.resource, agendamento)
    }

    getList(responseFunctions) {
        this.jsonRequest(responseFunctions, 'GET', this.resource)
    }

    getAgendamentosDoDia(onSucess, onError, onEnd, page, per_page, filter) {
        let queryParams = {
            page: page,
            per_page: per_page,
            unidade: filter.unidade,
            data: filter.data?? '',
        }

        this.jsonRequest({onSucess, onError, onEnd}, 'GET', this.resource + '/agendamentos-hoje', null, queryParams)
    }
    
}