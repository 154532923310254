<template>
  <v-card>
    <v-card-title>
      <span class="headline">Cadastro de agenda</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <div>
          <v-form class="p-lg-12 row" ref="form" v-model="valid" lazy-validation>
            <!-- CAMPO DE DATA INÍCIO -->
          <div class="col-lg-12 form-group clearfix mb-3">
            <v-text-field
              label="Data inicial"
              v-model="data_inicio"
              format="dd/mm/yy"
              :rules="validationData"
              clear-icon="clear"
              required
              style="margin: 0 5px; padding-top: 0px !important"
              :type="'date'"
              :outlined="false"
            />
          </div>

          <div class="col-lg-12 form-group clearfix mb-3">
            <v-text-field
              label="Data final"
              v-model="data_final"
              format="dd/mm/yy"
              :rules="validationData"
              clear-icon="clear"
              required
              style="margin: 0 5px; padding-top: 0px !important"
              :type="'date'"
              :outlined="false"
            />
          </div>
            
            <div class="col-lg-12 row">
              <div class="col-lg-12 form-group clearfix mb-3 text-dark">
                <label for="Horário">Turnos</label>
                <v-select
                  :items="horariosDisp"
                  :rules="validationTipoHorario"
                  required
                  label="Horário"
                  color="black"
                  solo
                  multiple
                  item-text="name"
                  item-value="id"
                  v-model="horarios"
                >
                  <template
                    v-slot:selection="{ attrs, item, select, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click="select"
                      @click:close="remove(item)"
                    >
                      <strong>{{ item.name }}</strong
                      >&nbsp;
                    </v-chip>
                  </template>
                </v-select>
              </div>
            </div>

              <div class="col-lg-12 row">
              <div class="col-lg-12 form-group clearfix mb-3 text-dark">
                <label for="weekDay">Dia da semana</label>
                <v-select
                  :items="weekDaysDisp"
                  :rules="validationTipoDiasDaSemanas"
                  required
                  label="Dia da semana"
                  color="black"
                  solo
                  multiple
                  item-text="name"
                  item-value="id"
                  v-model="weekDays"
                >
                  <template
                    v-slot:selection="{ attrs, item, select, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click="select"
                      @click:close="removeWeekDay(item)"
                    >
                      <strong>{{ item.name }}</strong
                      >&nbsp;
                    </v-chip>
                  </template>
                </v-select>
              </div>
              
              <div class="col-lg-12 form-group clearfix mb-3 text-dark">
                <label for="Horário">Unidade</label>
                <v-select
                  :items="unidades"
                  :rules="validationTipoUnidade"
                  required
                  label="Unidade"
                  solo
                  item-text="name"
                  item-value="unidade_id"
                  v-model="unidade_id"
                >
                </v-select>
              </div>
            </div>
            <!-- BOTÃO SUBMIT FORMULÁRIO -->
            <div>
              <v-btn color="#3E682A" text @click="$emit('close')">
                Cancelar
              </v-btn>
              <v-btn
                color="#3E682A"
                text
                @click="criarAgenda"
                :loading="loading"
              >
                <span>Criar Agenda</span>
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import AgendaService from "@/services/agenda_service.js";
import ValidationService from "@/services/validation_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";

export default {
  mixins: [VuexSnackbarMixin, VuexUsuarioMixin],
  beforeMount() {
    this.validationService = ValidationService;
  },
  props: {
    unidades: Array,
  },
  data: () => ({
    loading: false, // Guarda o estado do loading de submit
    valid: false, // Guarda a variável que guarda o estado da validação do formulário
    data_inicio: '',
    data_final: '',
    validationService: {},
    unidade_id: null,
    horarios: [],
    weekDays: [],
    weekDaysDisp: [
      { id: 0, name: "domingo" },
      { id: 1, name: "segunda" },
      { id: 2, name: "terça" },
      { id: 3, name: "quarta" },
      { id: 4, name: "quinta" },
      { id: 5, name: "sexta" },
      { id: 6, name: "sábado" },
    ],
    horariosDisp: [
      { id: 1, name: "manhã" },
      { id: 2, name: "tarde" },
      { id: 3, name: "noite" },
    ],
  }),
  computed: {

    validationData() {
      return [this.validationService.required("Informe uma data.")];
    },
    validationTipoHorario() {
      return [this.validationService.required("Informe um horário.")];
    },

    validationTipoUnidade() {
      return [this.validationService.required("Informe uma unidade.")];
    },

    validationTipoDiasDaSemanas() {
      return [this.validationService.required("Informe uma unidade.")];
    },
  },
  methods: {
    remove(item) {
      this.horarios = this.horarios.filter((h) => h != item.id);
    },

    removeWeekDay(item) {
      this.weekDays = this.weekDays.filter((h) => h != item.id);
    },

    criarAgenda() {
      const horarioAgendamento = {
        turnos: this.horarios,
        unidade_id: this.unidade_id,
        data_inicio: this.data_inicio,
        data_final: this.data_final,
        dias_semana: this.weekDays,
        // hora_final: this.horaFinal,
        // id_tipo_procedimento: this.TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento,
        // adicionado_por: this.$_GETTERS_usuario.id,
      };

      if (!this.$refs.form.validate() || !this.valid) {
        return;
      }

      this.loading = true;
      
      new AgendaService().criarAgenda(
        {
          onSucess: (status) => (body) => {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: status === 200 ? "sucess" : "error",
            });
            status === 200 && this.$emit("response");
            this.$refs.form.reset();
          },
          onError: () => {
            // this.$_ACTIONS_showSnackbarMessage({
            //   message: this.$global.messages.internalServerError,
            //   color: "error",
            // });
          },
          onEnd: () => {
            this.loading = false;
            this.$emit('reload')
            this.$emit('close')
          },
        },
        horarioAgendamento, true
      );
    },
  },
};
</script>
