<template>
  <div>
    <ul class="d-flex list-unstyled menu-left mb-0 pl-0 my-2 appBG-primary">
      <li class="float-left">
        <a href="/principal" class="logo">
          <span class="logo-lg">
            <img src="@/assets/images/logo-horizontal-n.png" alt="" width="145px" />
          </span>
          <span class="logo-sm">
            <img src="assets/images/logo-sm.png" alt="" height="24" />
          </span>
        </a>
      </li>
    </ul>
    <div class="slimscroll-menu w-100">
      <div class="left-side-menu shadow-none">
        <div>
          <div id="sidebar-menu">
            <ul class="metismenu appBG-primary" id="side-menu">
              <li v-for="(option, index) in options" :key="index">
                <RouterLink :to="option.path">
                  <i :class="option.icon + ''"></i>
                  <span class="ml-1"> {{ option.label }}</span>
                </RouterLink>
              </li>
            </ul>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuexUsuarioMixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [VuexUsuarioMixin],
  computed: {
    options() {
      const routes = this.$router.options.routes;
      const options = routes
        .map((route) => {
          const authObj = route.auth.find(
            (authObj) => authObj.profile == this.$_GETTER_perfil.id
          );
          const authorization = authObj ? authObj.authorization : null;
          return {
            authorization: authorization,
            label: route.label,
            name: route.name,
            path: route.path,
            icon: route.icon,
          };
        })
        .filter((option) => option.authorization);
      return options;
    },
  },
};
</script>
<style scoped>
.logo{
  margin: 15%;
}
.list-unstyled {
  background-color: #fff;
  list-style: none;
}

#side-menu {
  text-align-last: left;
}

.left-side-menu {
  background-color: #fff;
}

#sidebar-menu .nav-second-level {
  padding-left: 0;
}

#sidebar-menu > ul > li > a,
#sidebar-menu .nav-second-level li a {
  color: #fff;
  /* color: #981113; */
  font-weight: 700;
  text-align: left;
  text-decoration: none;
}

#sidebar-menu .nav-second-level li a {
  display: flex;
}

#sidebar-menu > ul > li > a:hover,
#sidebar-menu .nav-second-level li a:hover,
#sidebar-menu > ul > li > a:active,
#sidebar-menu .nav-second-level li a:active {
  color: #959595;
  opacity: 0.5;
  text-decoration: none !important;;
}

#sidebar-menu .nav-second-level li a i {
  display: inline-block;
  line-height: 1.25rem;
  margin: 0 10px 0 5px;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  font-size: 14px;
}
</style>