<template>
  <div>
    <!-- filtro -->
    <!-- <div class="my-2">
        <v-row justify="center">
          <v-expansion-panels focusable>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h5>Filtrar</h5>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <filtroAgendamento
                  @filtro="filtrarCalendario($event)"
                  @limparFiltro="limparFiltro"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </div> -->
    <v-sheet height="64">
      <v-toolbar flat>
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"
          >Hoje</v-btn
        >
        <v-select
          :items="unidadesSelect"
          label="Unidade"
          item-value="unidade_id"
          item-text="name"
          @change="atualizarHorarios"
          v-model="unidade_id"
        ></v-select>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-menu bottom right>
          <v-list>
            <v-list-item @click="calendario.typeView = 'day'">
              <v-list-item-title>{{ calendario.labels.day }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="calendario.typeView = 'week'">
              <v-list-item-title>{{
                calendario.labels.week
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="calendario.typeView = 'month'">
              <v-list-item-title>{{
                calendario.labels.month
              }}</v-list-item-title
              >5
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="600">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-calendar
        ref="calendar"
        v-model="calendario.focus"
        color="primary"
        :events="events"
        :event-color="getEventColor"
        :type="calendario.typeView"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        v-on:moved="chengEvent"

      >
      </v-calendar>
    </v-sheet>

    <!-- MODAL - AGENDA -->
    <v-dialog v-model="dialog" max-width="500px">
      <agendar-admin
        v-if="dialog"
        @close="closeDialog"
        :produtos="produtos"
        :agenda="getEventModalInput(modalAgendarCtx.eventParsed.input.list_id)"
        
      />
      <!--
        <modal-detalhes-agenda
          v-if="modalVerDetalhesAgendamento"
          :detalhes="detalhesAgenda"
          @close="dialog = false"
          @desmarcar="tratarRespostaDesmarcar"
        />
        <modal-horario-desabilitado
          v-if="modalHabilitarHorario"
          @close="dialog = false"
          :HorarioVencido="vencido"
        /> -->
    </v-dialog>
  </div>
</template>


<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import AgendaService from "@/services/agenda_service.js";
import UnidadeService from "@/services/unidade_service.js";
import ProdutosService from "@/services/produtos_service.js";
import agendarAdmin from "@/components/agendamento/agendar_admin.vue";

export default {
  mixins: [Mixin],
  components: {
    agendarAdmin,
  },
  data: () => ({
    renderComponent: 1,
    unidade_id: -1,
    type: "month",
    types: ["month", "week", "day", "4day"],
    mode: "stack",
    modes: ["stack", "column"],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      { text: "Mon, Wed, Fri", value: [1, 3, 5] },
    ],
    value: "",
    modalAgendarCtx: {},
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    editedHorarios: {},
    produtos: {},
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "nome",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    Horarios: [],
    loading: false,
    corHorarioCancelado: "grey",
    corHorarioOcupado: "primary",
    corHorarioLivre: "#3e682a",
    typeViewCalendario: "month",
    labelsCalendario: {
      month: "Mês",
    },
    calendario: {
      labels: {
        month: "Mês",
        week: "Semana",
        day: "Dia",
      },
      focus: "",
      typeView: "month",
      eventMore: false,
    },
    modalAgendar: false,
    horarios: [],
    detalhesAgenda: {},
    vencido: true,
    params: {},
    dialog: false,
    unidades: [],
  }),

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    

    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarHorarios();
      },
      deep: true,
    },

    search() {
      this.atualizarHorarios();
    },

    horariosCalendar() {
      this.horarios;
    },

    
  },

  computed: {
    unidadesSelect() {
      return this.unidades;
    },

    events() {
      const events = [];

      for (let i = 0; i < this.Horarios.length; i++) {
        const horarioAtual = this.Horarios[i];
        
        events.push({
          id: horarioAtual.id,
          name: horarioAtual.turno,
          start: horarioAtual.data.split(" ")[0],
          color: this.colors[4],
          list_id: horarioAtual.turno.charAt(0) + horarioAtual.data.split(" ")[0],
        });
      }

      return events;
    },
  },

  beforeMount() {
    this.atualizaUnidades();
  },

  mounted() {
    this.$refs.calendar.checkChange();
    this.atualizarHorarios();
    this.atualizaProdutos();
    this.forceRerender();
  },

  

  methods: {
    closeDialog() {
      this.dialog = false
    },

    response() {
      return 0;
    },

    getEventModalInput(list_id) {
      return this.events.filter(e => e.list_id == list_id)[0]
    },

    atualizaProdutos() {
      const produtosService = new ProdutosService();

      const onSucess = (status) => (body) => {
        if (status == 200) {
            this.produtos = body.data.produtos
            console.log(body)
          }
      };

      const onError = (error) => {
        console.log(error);
      };

      const onEnd = () => {
        return; //
      };

      produtosService.getList({ onSucess, onError, onEnd });
    },

    forceRerender() {
      this.renderComponent = Math.random();
      
      this.$nextTick (() => {
        this.renderComponent = Math.random();
      });
    },

    atualizaUnidades() {
      const unidadeService = new UnidadeService();

      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.unidades = body.data.unidades.map((unidade) => {
            return {
              unidade_id: unidade.id,
              name: unidade.nome_fantasia,
            };
          });

          this.unidade_id = this.unidades[0].unidade_id;
        } else {
          return;
        }
      };

      const onError = (error) => {
        console.log(error);
      };

      const onEnd = () => {
        this.atualizarHorarios();
        return; //
      };

      unidadeService.getList({ onSucess, onError, onEnd });
    },

    viewDay({ date }) {
      this.calendario.focus = date;
      // this.calendario.typeView = "day";
    },

    getEventColor(event) {
      return event.color;
    },

    setToday() {
      this.calendario.focus = "";
    },

    chengEvent() {
      this.atualizarHorarios();
    },

    prev() {
      this.$refs.calendar.prev();
      if (this.page > 0) {
        this.page--;
      }
    },

    next() {
      this.$refs.calendar.next();
      this.page++;
    },

    showEvent(context) {
      this.modalAgendarCtx = context;
      this.modalAgendar = true;
      this.dialog = true;
    },

    atualizarHorariosPosCadastro() {
      this.atualizarHorarios();
      this.closeDialogCadastrar();
    },

    atualizarCatagoriaPosEdicao() {
      this.dialogEdicao = false;
      this.atualizarHorarios();
    },

    atualizarHorarios() {
      this.loading = true;
      const horarios = new AgendaService();
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.Horarios = body.data.horarios.map((horario) => {
            return {
              id: horario.agenda_id,
              turno: ["Manhã", "Tarde", "Noite"][parseInt(horario.turno) - 1],
              data: horario.data.split(" ")[0],
            };
          });
          this.forceRerender()
        } else {
          console.log(body);
        }
      };

      const onError = (error) => {
        console.log(error);
      };

      const onEnd = () => {
        this.loading = false;
      };

      horarios.getAll(
        { onSucess, onError, onEnd },
        {
          unidade: this.unidade_id,
        }
      );
    },

    editItem(item) {
      this.editedHorarios = JSON.parse(JSON.stringify(item));
      this.dialogEdicao = true;
    },

    deleteItem(item) {
      this.editedHorarios = JSON.parse(JSON.stringify(item));
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();
      for (let i = 0; i < this.Horarios.length; i++) {
        if (this.Horarios[i].Horarios_id === this.editedHorarios.Horarios_id) {
          const agendaService = new agendaService();
          agendaService.deleteHorarios(
            {
              onSucess: (status) => (body) => {
                this.$_ACTIONS_showSnackbarMessage({
                  message:
                    body.message || this.$global.messages.internalServerError,
                  color: status === 200 ? "sucess" : "error",
                });
                status === 200 && this.Horarios.splice(i, 1);
              },
              onError: () => {
                this.$_ACTIONS_showSnackbarMessage({
                  message: this.$global.messages.internalServerError,
                  color: "error",
                });
              },
              onEnd: () => {},
            },
            this.editedHorarios.Horarios_id
          );
          break;
        }
      }
    },

    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.modalAgendarCtx = {};
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
