<template>
  <v-card>
    <v-card-title>
      <span class="headline">Agendar horário</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="cliente"
              :items="clientes"
              :search-input.sync="search"
              item-text="nome"
              item-value="cliente_id"
              return-object
              :loading="isLoading"
              label="Cliente"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              v-model="produto_id"
              item-text="produto"
              item-value="produto_id"
              :items="produtos"
              label="Vacina"
            ></v-autocomplete>
          </v-col>

          <v-col col="12">
            <v-checkbox
              v-model="checkboxAgendarParaOutro"
              :label="'A vacina será aplicada em outra pessoa'"
            ></v-checkbox>
          </v-col>

          <v-col v-show="checkboxAgendarParaOutro" cols="12">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nome da pessoa"
                  v-model="nome_pessoa"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field label="CPF" v-model="cpf_pessoa"></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Data de nascimento"
                  :type="'date'"
                  v-model="data_nascimento_pessoa"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#3E682A" text @click="closeModal"> Cancelar </v-btn>
      <v-btn color="#3E682A" :loading="loading" text @click="agendar">
        Agendar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import AgendamentoService from "@/services/agendamento_service.js";
import ClienteService from "@/services/cliente_service.js";

export default {
  mixins: [Mixin],
  props: {
    agenda: Object,
    produtos: Array,
  },

  watch: {
    search(val) {
      if (!val) {
        this.pacientes = [];
      }
      if (!val || !(val = val.trim()) || val.length < 3 || this.isLoading) {
        return;
      }

      // // Items have already been loaded
      // if (this.items.length > 0) return
      // // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      const onEnd = () => {
        this.isLoading = false;
        return this.search;
      };

      this.clientes = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.clientes = body.data.clientes;
          this.$emit("response");
        } else {
          //
        }
      };

      const onError = (err) => console.log(err);

      const clienteService = new ClienteService();
      clienteService.getAll(onSucess, onError, onEnd, 1, 6, val, null, null);
    },
  },

  mounted() {},
  data: () => ({
    agenda_id: "",
    cliente: "",
    produto_id: -1,
    loading: false,
    dialog: false,
    clientes: [],
    search: null,
    isLoading: false,
    nome_pessoa: "",
    cpf_pessoa: "",
    data_nascimento_pessoa: null,
    checkboxAgendarParaOutro: false,
  }),
  methods: {
    closeModal() {
      this.$emit('cancel');
    },
    
    agendar() {
      // const categoriaService = new CategoriaService()
      this.loading = true;

      const formData = {
        cliente_id: this.cliente.cliente_id,
        agenda_id: this.agenda.id,
        produto_id: this.produto_id,
        nome_paciente: this.nome_pessoa,
        cpf_paciente: this.cpf_pessoa,
        data_nascimento: this.data_nascimento_pessoa,
      };

      console.log(formData);

      const agendamentoService = new AgendamentoService();

      agendamentoService.register(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
              this.$emit("response");
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "error",
              });
            }
          },
          onError: (error) => {
            console.error(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
            this.$emit("close");
          },
        },
        formData
      );
    },
  },
};
</script>