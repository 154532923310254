<template>
  <v-card>
    <v-card-title>
      <span class="headline">Cadastro de clientes</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <div>
          <v-form
            class="p-lg-12 row"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <div class="col-lg-12 form-group clearfix mb-3">
              <v-text-field
                label="Nome"
                v-model="nome"
                :rules="validationNome"
                clear-icon="clear"
                required
                :type="'text'"
                :outlined="false"
              />
            </div>

            <div class="col-lg-12 form-group clearfix mb-3">
              <v-text-field
                label="E-mail"
                v-model="email"
                :rules="validationEmail"
                clear-icon="clear"
                required
                :type="'email'"
                :outlined="false"
              />
            </div>

            <div class="col-lg-12 form-group clearfix mb-3">
              <v-text-field
                label="Senha"
                v-model="senha"
                clear-icon="clear"
                :rules="validationSenha"
                required
                :type="'password'"
                :outlined="false"
              />
            </div>

            <v-col cols="12">
              <v-text-field
                v-model="cpf"
                :rules="validationCpf"
                label="CPF"
                v-mask="'###.###.###-##'"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="data_nasc"
                :type="'date'"
                :rules="validationNascimento"
                label="Data de nascimento"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="tel_um"
                label="Telefone 1"
                required
                :rules="validationTelefone"
                v-mask="'(##) #####-####'"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="tel_dois"
                label="Telefone 2"
                v-mask="'(##) #####-####'"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <p>Gênero</p>
              <v-radio-group required :rules="validationGenero" v-model="genero" column>
                <v-radio label="Masculino" value="M"></v-radio>
                <v-radio label="Feminino" value="F"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="cep"
                @change="buscaCep"
                required
                label="CEP"
                v-mask="'##.###-###'"
                :rules="validationCep"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                required
                v-model="logradouro"
                label="Logradouro"
                :rules="validationLogradouro"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                required
                v-model="numero"
                label="Número"
                :rules="validationNumero"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                required
                v-model="bairro"
                label="Bairro"
                :rules="validationBairro"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                required
                v-model="cidade"
                label="Cidade"
                :rules="validationCidade"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                required
                v-model="estado"
                :rules="validationEstado"
                label="Estado"
              ></v-text-field>
            </v-col>

            <!-- BOTÃO SUBMIT FORMULÁRIO -->
            <div>
              <v-btn color="#3E682A" text @click="$emit('close')">
                Cancelar
              </v-btn>
              <v-btn
                color="#3E682A"
                text
                @click="criarCliente"
                :loading="loading"
              >
                <span>Criar cliente</span>
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import ClienteService from "@/services/cliente_service.js";
import ValidationService from "@/services/validation_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import CEPService from "@/services/cep_service.js";

export default {
  mixins: [VuexSnackbarMixin, VuexUsuarioMixin],
  beforeMount() {
    this.validationService = ValidationService;
  },
  props: {
    unidades: Array,
  },
  data: () => ({
    valid: false, // Guarda a variável que guarda o estado da validação do formulário
    nome: "",
    senha: "",
    tel_um: "",
    tel_dois: "",
    data_nasc: "",
    validationService: {},
    clienteEdit: {},
    email: "",
    cpf: "",
    rg: "",
    genero: "",
    cep: "",
    logradouro: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    loading: false,
  }),
  computed: {
    validationNome() {
      return [this.validationService.required("Informe o nome.")];
    },

    validationEmail() {
      return [this.validationService.required("Informe o e-mail.")];
    },

    validationCpf() {
      return [this.validationService.required("Informe o cpf.")];
    },

    validationTelefone() {
      return [this.validationService.required("Informe um telefone.")];
    },

    validationSenha() {
      return [this.validationService.required("Informe a senha.")];
    },

    validationNascimento() {
      return [this.validationService.required("Informe a data de nascimento.")];
    },

    validationGenero() {
      return [this.validationService.required("Informe o gênero.")];
    },

    validationCep() {
      return [this.validationService.required("Informe o CEP.")];
    },

    validationLogradouro() {
      return [this.validationService.required("Informe o logradouro.")];
    },

    validationNumero() {
      return [this.validationService.required("Informe o número.")];
    },

    validationBairro() {
      return [this.validationService.required("Informe o bairro.")];
    },

    validationCidade() {
      return [this.validationService.required("Informe a cidade.")];
    },

    validationEstado() {
      return [this.validationService.required("Informe o estado.")];
    },
  },
  methods: {
    buscaCep() {
      const cepService = new CEPService();
      this.loading = true;
      cepService.buscaCep(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.logradouro = body.logradouro;
              this.estado = body.uf;
              this.cidade = body.localidade;
              this.bairro = body.bairro;
              this.complemento = body.complemento;
            }
          },
          onError: (error) => {
            return error;
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        this.cep.replace("-", "").replace(".", "")
      );
    },
    criarCliente() {
      const criacaoCliente = {
        nome: this.nome,
        email: this.email,
        senha: this.senha,
        data_nascimento: this.data_nasc,
        cpf: this.cpf,
        telefone_1: this.tel_um,
        telefone_2: this.tel_dois,
        rg: this.rg,
        genero: this.genero,
        cep: this.cep,
        logradouro: this.logradouro,
        numero: this.numero,
        bairro: this.bairro,
        cidade: this.cidade,
        estado: this.estado,
      };

      if (!this.$refs.form.validate() || !this.valid) {
        return;
      }
      this.loading = true;
      new ClienteService().register(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.errors[Object.keys(body.errors)[0]],
                color: "error",
              });
            }

            this.$refs.form.reset();
            this.$emit("reload");
            this.$emit("close");
          },
          onError: () => {
            // this.$_ACTIONS_showSnackbarMessage({
            //   message: this.$global.messages.internalServerError,
            //   color: "error",
            // });
          },
          onEnd: () => {
            this.loading = false;
            this.$emit("reload");
            this.$emit("close");
          },
        },
        criacaoCliente
      );
    },
  },
};
</script>
