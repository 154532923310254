<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Clientes</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="clientes"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog
            v-model="dialogEditarView"
            max-width="500px"
            transition="dialog-bottom-transition"
          >
            <modal-edicao
              :cliente="editedItem"
              :show="false"
              @close="closeEditItem(1)"
              @reload="atualizarListaClientes"

            />
          </v-dialog>

          <v-dialog
            v-model="dialogEditar"
            max-width="500px"
            transition="dialog-bottom-transition"
          >
            <modal-edicao
              :cliente="editedItem"
              :show="true"
              @close="closeEditItem(2)"
            />
          </v-dialog>

          <v-dialog v-model="dialogCadastrar" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#981113"
                dark
                class="mb-2"
                @reload="atualizarListaClientes"
                @close="dialogCadastrar=false"
                v-bind="attrs"
                v-on="on"
              >
                Novo cliente
              </v-btn>
            </template>
            <modal-cadastro
              v-if="dialogCadastrar"
              @close="closeDialogCadastrar"
              @response="atualizarLojaPosCadastro($event)"
            />
          </v-dialog>

          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small @click="setEdit(item, true)" class="mr-2" >
          mdi mdi-eye
        </v-icon>
        <v-icon
          small
          class="mr-2"
          :cliente="item"
          @click="setEdit(item, false)"
        >
          fas fa-edit
        </v-icon>
      </template>

      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#981113" dark @click="atualizarListaClientes">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ClienteService from "@/services/cliente_service.js";
import Mixin from "@/mixins/vuex_mixin.js";
import modalCadastro from "@/components/clientes/modal_cadastro";
import modalEdicao from "@/components/clientes/modal_edicao";

export default {
  mixins: [Mixin],
  components: {
    modalCadastro,
    modalEdicao,
  },
  data: () => ({
    cliente_detalhe: {},
    dialogCadastrar: false,
    dialogEditar: false,
    dialogEditarView: false,
    render: 0,
    dialog: false,
    loading: false,
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    cliente: {},
    clientes: [],
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "nome",
      },
      { text: "CPF", value: "cpf", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      nome: "",
      cpf: "",
      email: "",
    },
    defaultItem: {
      nome: "",
      cpf: "",
      email: "",
    },
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarListaClientes();
      },
      deep: true,
    },
    search() {
      this.atualizarListaClientes();
    },
  },

  mounted() {
    this.atualizarListaClientes();
  },

  methods: {

    closEditItem(view) {
      
      switch (view) {
        case 1:
          this.dialogEditarView = false
          break;
        case 2:
          this.dialogEditar = false
          break
      }
      
      this.editItem = {}
    },

    setEdit(item, edit) {
      this.editedItem = item

      if (edit) {
        this.dialogEditar = true;
      } else {
        this.dialogEditarView = true;
      }
    },
    
    forceRender() {
      this.render += 1;
    },

    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    atualizarListaClientes() {
      const clienteService = new ClienteService();
      this.loading = true;
      this.clientes = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.totalList = body.data.pagination.num_rows;
          this.clientes = body.data.clientes
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      clienteService.getAll(
        onSucess,
        onError,
        onEnd,
        this.options.page,
        this.options.itemsPerPage,
        this.search,
        this.options.ordenacao,
        this.options.desc
      );
    },

    loadingCliente(id) {
      this.cliente_detalhe = {};
      const clienteService = new ClienteService();
      clienteService.get(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.cliente_detalhe = body.data.clientes[0];
              console.log(this.cliente_detalhe);
              this.dialog = true;
            }
          },
          onError: (error) => {
            this.messageSnackbar = error;
            this.typeMessageSnackbar = "error";
            console.log(error);
          },
          onEnd: () => {},
        },
        id
      );
    },

    closeEditItem(e) {
      this.editedItem = {}
      if (e == 1) {
        this.dialogEditarView = false
      } else {
        this.dialogEditar = false
      }
    },

    editItem(item, bool) {
      this.editedIndex = this.clientes.indexOf(item);
      this.cliente = item;
      this.visualizar = bool;
      this.editedItem = Object.assign({}, item);
      this.loadingCliente(this.cliente.id);
    },

    close() {
      this.dialog = false;
      this.atualizarListaClientes();
      this.forceRender();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.dialogCadastro = false;
      if (this.editedIndex > -1) {
        Object.assign(this.clientes[this.editedIndex], this.editedItem);
      } else {
        this.clientes.push(this.editedItem);
      }
      this.cliente_detalhe = false;
      this.options.ordenacao = false;
      this.options.desc = false;
      this.close();
      this.atualizarListaClientes();
      this.forceRender();
    },
  },
};
</script>