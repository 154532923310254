<template>
  <v-row>
    <v-col cols="6">
      <v-select
        :items="produtos"
        :loading="loadingProdutos"
        :search-input.sync="search"
        item-text="produto"
        item-value="produto_id"
        label="Produto"
        placeholder="Pesquise um produto"
        v-model="produto.produto_id"
      >
      </v-select>
    </v-col>

    <v-col cols="5">
      <v-text-field
        type="number"
        min="0"
        v-model="produto.quantidade"
        label="Quantidade"
        hide-details="auto"
        @change="verificarQuantidade"
      ></v-text-field>
    </v-col>

    <v-btn
      v-if="canDelete()"
      @click="$emit('removeProdutoModal', index)"
      class="mt-4"
      icon
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-row>
</template>

<script>
export default {
  props: {
    produto: Object,
    produtos: Array,
    index: Number,
    adjacent: Array,
  },

  data() {
    return {
      loadingProdutos: false,
      search_category: "",
      search: null,
      error: false,
    };
  },

  methods: {
    remover(item) {
      const index = item.categoria_id;
      this.produto.categorias = this.produto.categorias.filter(
        (p) => p != index
      );
    },

    verificarQuantidade() {
      if (this.produto.quantidade <= 0 && this.adjacent.length > 1) {
        this.$emit('removeProdutoModal', this.index)
      }
    },

    canDelete() {
      return this.adjacent.length > 1;
    },

    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.states.filter((e) => {
          return (
            (e.state || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
          );
        });
        this.loading = false;
      }, 100);
    },
  },
  watch: {
    search(val) {
      val && this.querySelections(val);
    },
  },
};
</script>
