import { global } from '@/config/constants.js'

const profile = description => {
    const profileObj = global.profiles.find(profile => profile.description === description)
    const id = (profileObj || {}).id
    return id || 0
}

const authorizations = {
    [profile('admin')]: {
        'dashboard': {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
        'clientes' : {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
        'loja': {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
        'produtos' : {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
        'categorias': {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
        'unidades': {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
        'usuario': {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
        'horarios': {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
        'agendamento': {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
        'estoque': {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
        'vendas': {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
        'gesto_vacinal': {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
        'cancelamentos': {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
    },

    [profile('atendente')]: {
        'dashboard': {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
        'clientes' : {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
        // 'loja': {
        //     c: 1,
        //     r: 1,
        //     u: 1,
        //     d: 1
        // },
        // 'produtos' : {
        //     c: 1,
        //     r: 1,
        //     u: 1,
        //     d: 1
        // },
        // 'categorias': {
        //     c: 1,
        //     r: 1,
        //     u: 1,
        //     d: 1
        // },
        // 'unidades': {
        //     c: 1,
        //     r: 1,
        //     u: 1,
        //     d: 1
        // },
        // 'usuario': {
        //     c: 1,
        //     r: 1,
        //     u: 1,
        //     d: 1
        // },
        'horarios': {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
        'agendamento': {
            c: 1,
            r: 1,
            u: 1,
            d: 1
        },
    },
    
    [profile('unauthenticated')]: {
        'home': {
            c: 0,
            r: 1,
            u: 0,
            d: 0
        },
        'login': {
            c: 1,
            r: 0,
            u: 0,
            d: 0
        },
        'esqueci-minha-senha': {
            c: 1,
            r: 0,
            u: 0,
            d: 0
        },
    }
}

export default authorizations