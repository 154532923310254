<template>
  <v-card>
    <v-card-title>
      <span class="headline">Cadastrar produto</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <form class="col-12">
              <div class="row col-12">
                <v-text-field
                  class="col-4" 
                  v-model="nome"
                  label="Nome"
                  :error-messages="error.nome"
                  :rules="[rules.required]"
                >
                </v-text-field>
                <v-text-field v-model="preco"
                  class="col-4" 
                  label="Preço"
                  prefix="R$"
                  v-mask="mask"
                  :error-messages="error.preco"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-autocomplete
                  class="col-4"
                  v-model="categorias_produto"
                  :items="categorias"
                  :loading="loadingCategorias"
                  :search-input.sync="search_category"
                  item-text="nome"
                  item-value="categoria_id"
                  label="Categorias"
                  placeholder="Pesquise uma categoria"
                  filled
                  multiple
                  :error-messages="error.categorias_produto"
                  :rules="[rules.required_arr]"
                >
                  <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remover(data.item)"
                      >
                        {{ data.item.nome }}
                      </v-chip>
                  </template>
                </v-autocomplete>
              </div>
              <div class="row col-12">
                 <v-textarea
                  outlined
                  rows="3"
                  label="O que previne?"
                  v-model="previne"
                ></v-textarea>
                 <v-textarea
                  class="ml-1"
                  outlined
                  rows="3"
                  label="Contraindicação?"
                  v-model="contraindicacao"
                ></v-textarea>
              </div>
              <div class="row col-12">
                 <v-textarea
                  outlined
                  rows="3"
                  label="Indicação ?"
                  v-model="indicacao"
                ></v-textarea>
                 <v-textarea
                  class="ml-1"
                  outlined
                  rows="3"
                  label="Esquema de doses?"
                  v-model="doses"
                ></v-textarea>
              </div>
              <div class="row col-12">
                 <v-textarea
                  outlined
                  rows="3"
                  label="Outras recomendaçoes?"
                  v-model="recomendacao"
                ></v-textarea>
              </div>
          </form>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#3E682A" text @click="$emit('close')">
        Cancelar
      </v-btn>
      <v-btn color="#3E682A" :loading="loading" text @click="cadastrar">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ProdutoService from '@/services/produtos_service.js'
import CategoriaService from '@/services/categoria_service.js'
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    includeThousandsSeparator: false,
    allowNegative: false,
  });

export default {
  mixins:[Mixin],
  data: () => ({
    mask: currencyMask,
    nome: '',
    preco: '',
    tipo_produto: '',
    recomendacao:'',
    doses:'',
    indicacao:'',
    previne:'',
    contraindicacao:'',
    loading: false,
    loadingCategorias: false,
    categorias: [],
    categorias_produto: [],
    search_category:"",
    formCadastro:"",
    error: {
        nome: "",
        preco: "",
        categorias_produto: "",
    },
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
      required_arr: (value) => (value.length > 0) || "Campo obrigatório.",
    },
    
  }),
  mounted() {
    this.formCadastro = document.forms[0];
    this.loadCategorias()
  },
    methods: {
    cadastrar() {
      if (!this.formCadastro.checkValidity()) {
        return;
      }

      var formdata = new FormData()
      formdata.append("nome", this.nome)
      formdata.append("categorias", this.categorias_produto)
      formdata.append("preco", parseFloat(this.preco))
      formdata.append("clinica_id", 1)
      formdata.append("previne", this.previne)
      formdata.append("contraindicacao", this.contraindicacao)
      formdata.append("indicacao", this.indicacao)
      formdata.append("doses", this.doses)
      formdata.append("recomendacao", this.recomendacao)
      const produtoService = new ProdutoService()
      this.loading = true
      produtoService.cadastrar({
        onSucess: status => body => {
          if (status === 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: body.message || this.$global.messages.internalServerError,
              color: 'sucess'
            })
          }
          this.$emit('response')
        },
        onError: error => {
          this.messageSnackbar = error
          this.colorSnackbar = 'error'
        },
        onEnd: () => {
          this.loading = false
        },
      }, formdata)
    },
    loadCategorias(){
      this.loadingCategorias = true
      const categoriaService = new CategoriaService()
      this.categorias=[];
      const onSucess = (status) => (body) => {
          if (status === 200) {
          this.categorias = body.data.categorias
          } else {
            console.log(body);
          }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
            this.loadingCategorias = false;
      };
      categoriaService.getList({onSucess, onError, onEnd});
    },
    remover (item) {
      const index = this.categorias_produto.indexOf(item.categoria_id)
      console.log(index)
      if (index >= 0) this.categorias_produto.splice(index, 1)
    },
  },
}
</script>