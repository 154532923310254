<template>
  <div class="pt-5">
    <div class="w-100 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card shadow">
              <div class="card-body p-4">
                <div class="text-center mb-4">
                  <a href="index.html">
                    <span>
                      <img
                        src="@/assets/images/logo-login.png"
                        alt=""
                        weigth="300px"
                      />
                    </span>
                  </a>
                </div>

                <div class="pt-2 form-login">
                  <h4>Verifique seu email para mudar senha.</h4>
                  <div class="form-group mb-3">
                    <label class="text-left" for="EmailUsuario">Email</label>
                    <input
                      class="form-control"
                      v-model="email"
                      type="email"
                      id="EmailUsuario"
                      required=""
                      placeholder="Escreva seu email"
                    />
                  </div>

                  <div class="form-group mb-0 text-center">
                    <button
                      class="btn border-primary btn-block btn-rounded width-lg font-weight-bold appBG-primary"
                      @click.prevent="verificarEmail"
                    >
                      <span v-if="!loading"> Confirmar </span>
                      <v-progress-circular
                        v-else
                        indeterminate
                        color="white"
                      ></v-progress-circular>
                    </button>
                    <div class="justify-content-md-center mt-2">
                      <button
                        class="btn border-primary btn-block btn-rounded width-lg font-weight-bold appBG-primary"
                        @click="voltar"
                      >
                        Voltar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UsuarioService from "@/services/usuario_service";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  data: () => ({
    loading: false,
    email: "",
  }),

  methods: {
    voltar() {
      this.$router.replace("login");
    },
    verificarEmail() {
      this.loading = true;
      const usuarioService = new UsuarioService();
      usuarioService.esqueciSenha(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
            }
            if (status === 204) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message || "Email não encontrado",
                color: "warning",
              });
            }
          },
          onError: (error) => {
            console.error(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        {email: this.email}
      );
    },
  },
};
</script>