<template>
  <v-card>
    <v-card-title>
      <span class="headline">Edição de loja</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="loja.nome" label="Nome"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.lazy="loja.preco"
              v-money="money"
              v-model="loja.preco"
              label="Preço"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <produto-pacote-item
              v-for="(produto, index) in loja.produtos"
              :index="index"
              :produto="produto"
              :produtos="produtos"
              :adjacent="loja.produtos"
              v-bind:key="index"
              @removeProdutoModal="removeProdutoModal"
            />
          </v-col>

          <v-btn
            @click="
              loja.produtos.push({
                quantidade: 0,
              })
            "
            class="mt-4"
            icon
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-col cols="6" sm="6">
            <v-row>
              
              <v-col class="d-flex" cols="12" sm="12">
                <v-row class="d-flex flex-column align-content-center">
                  <v-avatar class="profile mb-3" color="grey" size="364" tile>
                    <v-img :src="loja.foto"></v-img>
                  </v-avatar>
                  <v-file-input
                    counter
                    outlined
                    show-size
                    style="width:364px"
                    placeholder="Foto do pacote"
                    prepend-icon="mdi-camera"
                    @change="uploadFoto"
                  ></v-file-input>
                </v-row>
              </v-col>
              <v-col class="d-flex" cols="12" sm="12">
                <v-radio-group v-model="loja.ativo" row>
                  <v-radio label="Disponível" color="blue" value="1"></v-radio>
                  <v-radio
                    label="Indisponível"
                    color="blue"
                    value="0"
                  ></v-radio>
                </v-radio-group>
              </v-col>

            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn
        class="btn-app-primary"
        style="color: #fff !important"
        :loading="loading"
        text
        @click="edit"
        :disabled="!allowEdition"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import LojaService from "@/services/loja_service.js";
import Mixin from "@/mixins/vuex_mixin.js";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import ProdutoService from "@/services/produtos_service.js";
import ProdutoPacoteItem from "@/components/templates/ProdutoPacoteItem.vue";

const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
});

export default {
  mixins: [Mixin],
  props: {
    loja: {
      type: Object,
      required: true,
      validator: (obj) => {
        return obj.pacote_id && obj.nome;
      },
    },
    produtos: Array,
  },
  components: {
    ProdutoPacoteItem,
  },
  data: () => ({
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    nome: "",
    preco: "",
    mask: currencyMask,
    search_category: "",
    produto: [],
    status: "",
    formCadastro: "",
    modal_produtos: [{ quantidade: 0 }],
    error: {
      nome: "",
      preco: "",
      produto: "",
    },
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
      required_arr: (value) => value.length > 0 || "Campo obrigatório.",
    },
    loading: false,
  }),

  beforeMount() {
    this.loadProdutos();
    this.loja.preco *= 100
  },
  methods: {
    edit() {
      const loja = {};

      Object.assign(loja, this.loja);
      const preco = parseFloat(
        this.loja.preco
          .replace(".", "")
          .replace(" ", "")
          .replace(",", ".")
          .replace("R$", "")
      );

      loja.preco = preco;
      loja.nome = this.loja.nome;
      loja.foto = this.loja.foto;
      loja.id = this.loja.loja_id;

      const makeProduto = (produto) => {
        return {
          produto_id: produto.produto_id,
          quantidade: produto.quantidade,
        };
      };

      const produtos = this.loja.produtos
        .map(makeProduto)
        .filter((produto) => produto.quantidade > 0);

      const camposInvalidosNoProduto = (produto) => {
        return !produto.produto_id && !produto.quantidade;
      };

      if (this.loja.produtos.some(camposInvalidosNoProduto)) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Todos os campos devem ser preenchidos.",
          color: "error",
        });

        return;
      }

      if (this.loja.produtos.some((produto) => produto.quantidade == 0)) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "A quantidade dos produtos deve ser maior que zero.",
          color: "error",
        });

        return;
      }

      loja.produtos = produtos;

      const lojaService = new LojaService();
      this.loading = true;
      lojaService.updateLoja(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message || "Item da loja atualizado com sucesso!",
                color: "sucess",
              });
            }
          },
          onError: () => {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
            this.$emit("response", loja);
          },
        },
        loja
      );
    },

    removeProdutoModal(index) {
      this.loja.produtos = this.loja.produtos.filter((_, ix) => ix != index);
    },

    uploadFoto(e) {
      // const f = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        this.loja.foto = reader.result;
      };
      reader.readAsDataURL(e);
      // alert(e[0].getAsDataUrl())
    },

    //----------------------------------------------------------------------------------------------------------------//

    loadProdutos() {
      this.loadingProdutos = true;
      const produtoService = new ProdutoService();
      this.produtos = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.produtos = body.data.produtos;
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loadingProdutos = false;
      };
      produtoService.getList({ onSucess, onError, onEnd });
    },

    // ---------------------------------------------------------------------------------------------------------------//

    remover(item) {
      const index = this.produto.indexOf(item.produto_id);
      console.log(index);
      if (index >= 0) this.produto.splice(index, 1);
    },
  },
  computed: {
    allowEdition() {
      return true;
    },
  },
};
</script>
