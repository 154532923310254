<template>
  <div class="w-100">
    <span class="row">
      <v-sheet class="mx-auto mt-4" elevation="0" max-width="100%">
        <v-slide-group
          v-model="indexCompromissoSelecionado"
          class="pa-4"
          center-active
          show-arrows
        >
          <v-slide-item
            v-for="(compromisso, i) in compromissosNaoAtrasados"
            :key="i"
            v-slot="{ active, toggle }"
          >
            <component
              :is="componenteCard(active, compromisso.idConsulta)"
              :procedimento="compromisso.procedimento"
              :nome-paciente="compromisso.nomePaciente"
              :hora-inicial="compromisso.horarioInicio"
              :hora-final="compromisso.horarioFinal"
              :tipo-procedimento="compromisso.tipoProcedimento"
              @select="selecionarAgendamento(toggle, compromisso)"
              @unselect="limparSelecao"
            />
          </v-slide-item>
        </v-slide-group>

        <v-expand-transition>
          <v-sheet v-if="indexCompromissoSelecionado != null" tile>
            <div
              class="row mx-auto mb-4 py-6 px-4 shadow bg-cinza rounded"
              style="max-width: 300px"
            >
              <div class="col-md-12">
                <v-row justify="center">
                  <v-select
                    v-model="formulario"
                    item-text="titulo"
                    item-value="id"
                    :items="formularios"
                    :loading="loadingFormularios"
                    label="Tipo de formulários"
                  ></v-select>
                </v-row>
                <v-row class="mt-6" justify="center">
                  <v-btn v-if="procedimentoPossuiFormularios && formulario"
                    color="green darken-4"
                    dark
                    class="mt-3"
                    @click.stop="iniciarConsulta"
                  >
                    Iniciar atendimento
                  </v-btn>
                  <div v-else>
                    <span v-if="procedimentoPossuiFormularios || !telaCarregada">Selecione um formulário para poder realizar o atendimento.</span>
                    <span v-else style="color: red">Adicione um formulário a esse procedimento para poder realizar o atendimento.</span>
                  </div>
                </v-row>
                <v-row class="mt-6" justify="center">
                  <v-btn
                    color="grey darken-2"
                    dark
                    @click.stop="registrarPacienteAusente"
                  >
                    Paciente ausente
                  </v-btn>
                </v-row>
              </div>
            </div>
          </v-sheet>
        </v-expand-transition>
      </v-sheet>
    </span>
  </div>
</template>

<script>
import CardPacienteAnterior from "@/components/dashboards/dashboard_medico/fila_atual/card_paciente_anterior";
import CardPacienteAtual from "@/components/dashboards/dashboard_medico/fila_atual/card_paciente_atual";
import CardPacientePosterior from "@/components/dashboards/dashboard_medico/fila_atual/card_paciente_posterior";
// import FormularioService from '@/services/formulario_service.js'
export default {
  components: {
    CardPacienteAnterior,
    CardPacienteAtual,
    CardPacientePosterior,
  },
  props: {
    compromissos: { type: Array, required: true },
  },
  beforeMount(){
    this.selectNext()
  },

  data: () => ({
    formularios: [],
    formulario: '',
    procedimento: '',
    indexCompromissoSelecionado: 0,
    idAgendamentoSelecionado: null,
    loadingFormularios: false,
    telaCarregada: false
  }),
  computed: {
    compromissosNaoAtrasados() {
      return this.compromissos.filter((compromisso) => !compromisso.emAtraso);
    },
    procedimentoPossuiFormularios() {
      return this.formularios.length > 0;
    }
  },
  methods: {
    selectNext(){
      let compromisso={}
      for (let index = 0; index < this.compromissosNaoAtrasados.length; index++) {
          compromisso = this.compromissosNaoAtrasados[index]
        if (compromisso.idConsulta == null) {
          this.selecionarAgendamento(function(){}, compromisso)
          this.procedimento = compromisso.idProcedimento
          this.idAgendamentoSelecionado = compromisso.idAgendamento;
          this.indexCompromissoSelecionado = index;
          this.carregarTiposFormularios();
          this.$emit("paciente", {nome: compromisso.nomePaciente})
          break
        }
      }
      
    },
    // carregarTiposFormularios() {
    //   this.loadingFormularios = true;
    //   this.formularios = []
    //   const formularioService = new FormularioService();
    //   formularioService.getAll(
    //     {
    //       onSucess: (status) => (body) => {
    //         if (status === 200) {
    //           this.telaCarregada = true;
    //           body.data.formularios.forEach((form) => {
    //             let formulario = [];
    //             formulario["id"] = form.id,
    //             formulario["titulo"] = form.titulo;
    //             formulario["descricao"] = form.descricao;
    //             formulario["quantidade_perguntas"] = form.quantidade_perguntas;
    //             this.formularios.push(formulario);
    //           });
    //         }
    //       },
    //       onError: (error) => {
    //         console.log(error);
    //       },
    //       onEnd: () => {
    //         this.loadingFormularios = false;
    //       },
    //     },
    //     {
    //       procedimento: this.procedimento,
    //       ativo:1
    //     }
    //   );
    // },
    iniciarConsulta() {
      this.$emit("iniciar-consulta", {
        idAgendamentoSelecionado: this.idAgendamentoSelecionado,
        idFormulario: this.formulario
      });
    },
    limparSelecao() {
      this.indexCompromissoSelecionado = null;
      this.idAgendamentoSelecionado = null;
    },
    registrarPacienteAusente() {
      this.$emit("paciente-ausente", {
        idAgendamentoSelecionado: this.idAgendamentoSelecionado,
      });
    },
    selecionarAgendamento(toggle, compromisso) {
      toggle();
      this.procedimento = compromisso.idProcedimento
      this.idAgendamentoSelecionado = compromisso.idAgendamento;
      this.carregarTiposFormularios();
      this.$emit("paciente", {nome: compromisso.nomePaciente})
    },
    componenteCard(active, idConsulta) {
      return {
        "00": "CardPacientePosterior",
        "01": "CardPacienteAnterior",
        10: "CardPacienteAtual",
        11: "CardPacienteAtual",
      }[`${+active}${+!!idConsulta}`];
    },
  },
};
</script>