import HTTPService from '@/services/http_service.js'

export default class CategoriaService extends HTTPService {
    resource = 'pedido-cancelamento'

    cadastrar (responseFunctions, request) {
        this.request(responseFunctions, 'POST', this.resource, request)
    }

    // getExames (responseFunctions, nome) {
    //     let imprevisto = nome ?? ''
    //     if (imprevisto === '') {
    //         this.request(responseFunctions, 'GET', this.resource, null)
    //     } else {
    //         this.request(responseFunctions, 'GET', this.resource, null, nome)
    //     }
    // }

    getList (responseFunctions) {
      this.request(responseFunctions, 'GET', this.resource,null)
    }

    getAll (onSucess, onError, onEnd, page, per_page, filter, order, desc) {
      const responseFunctions = {
          onSucess,
          onError,
          onEnd
      }
      let queryparams ={
        page: page,
        per_page: per_page
      }
      if(filter ===''){
        if(order){
          let queryparams ={
            page: page,
            per_page: per_page,
            sort: desc?'nome':'-nome'
          }
          this.request(responseFunctions, 'GET', this.resource,null,queryparams)
        }else{
          this.request(responseFunctions, 'GET', this.resource,null,queryparams)
        }
      }else{
        let queryparams ={
          page: page,
          per_page: per_page,
          nome: filter,
        }
        if(order){
          let queryparams ={
            page: page,
            per_page: per_page,
            nome: filter,
            sort: desc?'nome':'-nome'
          }
          this.request(responseFunctions, 'GET', this.resource,null,queryparams)
        }else{
          this.request(responseFunctions, 'GET', this.resource, null, queryparams)
        }
      }
    }


    update ({ onSucess, onError, onEnd }, pedido) {
        const uri = `${this.resource}/delete`
        const request = {
          status: 0,
          id: pedido.cancelamento_id,
        }
        this.request({ onSucess, onError, onEnd }, 'POST', uri, request)
    }

} 