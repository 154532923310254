
export default {
    data:() => ({
        key: "secretPass",
        remember:{},
    }),
    
    methods: {
        getRememberAny(){
            return (localStorage.getItem('email')) !== ""?true:false
        },
        getRemember() {
            let credencias = {}
            credencias.email = localStorage.getItem('email');
            credencias.senha = this.CryptoJS.AES.decrypt(localStorage.getItem('senha'), this.key, ).toString(this.CryptoJS.enc.Utf8);
            console.log(credencias.senha)
            return credencias
        },
        setRemember (credencias) {
            console.log(credencias.senha)
            let pssword = this.CryptoJS.AES.encrypt( credencias.senha, this.key).toString()
            localStorage.setItem('email',credencias.email);
            localStorage.setItem('senha', pssword );
        },
        deletRemember () {
            localStorage.setItem('email','');
            localStorage.setItem('senha','');
        }
    }
}