<template>
  <div @click="$emit('select')" class="card-paciente-posterior">
    <card-fila
        :nome-paciente="nomePaciente"
        :hora-inicial="horaInicial"
        :hora-final="horaFinal"
        :procedimento="procedimento"
        :tipo-procedimento="tipoProcedimento"
        :cor="cor"
        @mouseover="cor = corHover"
        @mouseout="cor = corHoverOut"
    />
  </div>
</template>

<script>
import CardFila from "./card_fila";
export default {
  components: { CardFila },
  data: () => ({
    cor: '#6B8E23',
    corHover: 'rgb(143, 164, 44)',
    corHoverOut: '#6B8E23',
  }),
  props: {
    nomePaciente: {type: String, required: true},
    horaInicial: {type: String, required: true},
    horaFinal: {type: String, required: true},
    procedimento: {type: String, required: true},
    tipoProcedimento: {type: String, required: true},
  }
}
</script>

<style>
  .card-paciente-posterior {
    cursor: pointer
  }
  .card-paciente-posterior:hover {
    cursor: pointer
  }
</style>