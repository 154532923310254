<template>
  <v-card
    class="ma-4 h-90"
    elevation="2"
    rounded
    max-width="500px"
    width="400px"
    hover
    :style="{ backgroundColor: cor }"
    @mouseover="$emit('mouseover')"
    @mouseout="$emit('mouseout')"
  >
    <v-card-title class="titulo-cards-fila" style="color: #fff">
      <span>{{ tipoProcedimento }}</span>
      <hr />
    </v-card-title>
    <v-card-text>
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="text-left" style="color: #fff">
          {{ nomePaciente }}
        </h5>
        <h5 style="color: #fff">{{ horaInicial }}</h5>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <!-- <p
          class="descricao-procedimento "
          style="color: #fff"
          v-text="procedimento"
        > -->
        <!-- {{ procedimento }} -->
        <!-- </p> -->
        <v-list-item-subtitle
          class="descricao-procedimento text-left my-0"
          v-text="procedimento"
          style="color: #fff"
        ></v-list-item-subtitle>
        <h6 class="w-30 tempo-procedimento my-0 text-right" style="color: #fff">
          {{ horaInicial + " - " + horaFinal }}
        </h6>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    tipoProcedimento: { type: String, required: true },
    procedimento: { type: String, required: true },
    nomePaciente: { type: String, required: true },
    horaInicial: {
      type: String,
      required: true,
      validator: (str) => /^\d{2}:\d{2}$/.test(str),
    },
    horaFinal: {
      type: String,
      required: true,
      validator: (str) => /^\d{2}:\d{2}$/.test(str),
    },
    cor: { type: String, required: true },
  },
};
</script>
<style scoped>
.titulo-cards-fila span {
  margin-right: 1rem;
  font-size: 1rem;
}

.titulo-cards-fila hr {
  display: block;
  width: 100%;
  color: #fff;
  margin: 0;
}

.tempo-procedimento {
  font-size: 0.8rem;
}

.descricao-procedimento {
  font-size: 0.8rem;
}

.h-98 {
  height: 90%;
}

.w-30 {
  width: 30%;
}
</style>