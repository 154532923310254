import HTTPService from '@/services/http_service.js'

export default class ProdutoService extends HTTPService {
    resource = 'produto'
    cadastrar (responseFunctions, produto) {
        this.jsonRequest(responseFunctions, 'POST', this.resource, produto)
    }

    getList (responseFunctions) {
        this.request(responseFunctions, 'GET', this.resource,null)
    }

    // self_register (responseFunctions, paciente) {
    //     this.request(responseFunctions, 'POST', 'auth/'+this.resource+'/auto-cadastro', paciente)
    // }
    
    // update (responseFunctions, paciente, idPaciente) {
    //     this.fileRequest(responseFunctions, 'POST', this.resource+"/"+idPaciente, paciente )
    // }
    
    // updateObs (responseFunctions, obs, idPaciente) {
    //     this.request(responseFunctions, 'POST', this.resource+"/observacao/"+idPaciente, obs )
    // }

    // get (responseFunctions, idPaciente) {
    //     const url = this.resource + '/' + idPaciente
    //     this.request(responseFunctions, 'GET', url)
    // }

    updateProduto (responseFunctions, produto) {
      const updateResource = `${this.resource}/${produto.produto_id}`
      // tratamento do objeto      
      produto.clinica_id = 1
      produto.nome = produto.produto

      this.request(responseFunctions, 'POST', updateResource, produto)
    }

    deleteProduto (responseFunctions, produto) {
      const updateResource = `${this.resource}/${produto.produto_id}`
      // tratamento do objeto
      produto.clinica_id = 1

      produto.nome = produto.produto
      produto.ativo = 0

      this.request(responseFunctions, 'POST', updateResource, produto)
    }

    getAll (onSucess, onError, onEnd, page, per_page, filter, order, desc) {
        const responseFunctions = {
            onSucess,
            onError,
            onEnd
        }
        let queryparams ={
          page: page,
          per_page: per_page
        }
        if(filter ===''){
          if(order){
            let queryparams ={
              page: page,
              per_page: per_page,
              sort: desc?'nome':'-nome'
            }
            this.request(responseFunctions, 'GET', this.resource,null,queryparams)
          }else{
            this.request(responseFunctions, 'GET', this.resource,null,queryparams)
          }
        }else{
          let queryparams ={
            page: page,
            per_page: per_page,
            nome: filter,
          }
          if(order){
            let queryparams ={
              page: page,
              per_page: per_page,
              nome: filter,
              sort: desc?'nome':'-nome'
            }
            this.request(responseFunctions, 'GET', this.resource,null,queryparams)
          }else{
            this.request(responseFunctions, 'GET', this.resource, null, queryparams)
          }
        }
      }

    // isCpf(input){
    //    const eCpf = input ? input.replace(/(\.|-)/g, '') : ''
    //    return isNaN(eCpf);
    // }
    // getPacienteByName (responseFunctions, nome) {
    //     this.request(responseFunctions, 'GET', this.resource+'?nome='+nome, null)
    // }

    // getPacienteByUsuarioID (responseFunctions, idUsuario) {
    //     this.request(responseFunctions, 'GET', this.resource + '/usuario/' + idUsuario)
    // }

    // searchByNome (vm) {
    //     return nome =>{
    //         if (!nome) {
    //             vm.pacientes = [];
    //             vm.paciente=''
    //         }
    //         if (!nome || !(nome = nome.trim()) || nome.length < 3 || vm.loading_paciente) {
    //             return;
    //         }
    //         vm.loading_paciente = true;
    //         vm.pacientes = [];
    //         const requestFunctions = (
    //             {
    //                 onSucess: (status) => (body) => {
    //                     if (status === 200) {
    //                         vm.pacientes = body.data.pacientes;
    //                     } else {
    //                         vm.pacientes = [];
    //                     }
    //                 },
    //                 onError: (error) => {
    //                     console.error(error);
    //                     vm.pacientes = [];
    //                 },
    //                 onEnd: () => {
    //                     vm.loading = false;
    //                 },
    //             },
    //             this.getPacienteByName(requestFunctions, nome)
    //         );
    //     }
    // }

} 