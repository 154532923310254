<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Pedidos de cancelamento de vendas
        </h3>
      </div>
    </div>
    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="pedidos_cancelamento"
      :options.sync="options"
      :server-items-length="options.totalList"
      :loading="loading"
      :item-class="getRowStyle"
      sort-by="descricao"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-dialog
            v-if="dialogEdicao"
            v-model="dialogEdicao"
            max-width="500px"
          >
            <modal-edicao
              v-if="dialogEdicao"
              :produto="editedProduto"
              :unidade_id="unidade_id"
              @close="dialogEdicao = false"
              @atualizar="atualizarVendas"
              @response="atualizarTipoDeProcedimentoPosEdicao($event)"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="getRowStyle(item) == ''"
          small
          class="mr-2"
          @click="remover(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#981113" dark @click="atualizarcancelamentosDoDia">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import modalEdicao from "@/components/estoque/modal_estoque_operacao.vue";
import CancelamentosService from "@/services/cancelamento_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  components: { modalEdicao },
  data: () => ({
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    editedProduto: {},
    unidades: [],
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
      totalPages: 0,
    },
    headers: [
      {
        text: "Motivo",
        align: "start",
        sortable: false,
        value: "motivo",
      },
      {
        text: "Nome",
        align: "start",
        sortable: false,
        value: "cliente_nome",
      },
      {
        text: "CPF",
        align: "start",
        sortable: false,
        value: "cpf",
      },
      {
        text: "Telefone 1",
        align: "start",
        sortable: false,
        value: "cliente_telefone_1",
      },
      {
        text: "Telefone 2",
        align: "start",
        sortable: false,
        value: "cliente_telefone_2",
      },
      {
        text: "Código de transação",
        align: "start",
        sortable: false,
        value: "transacao_cod",
      },

      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    compras: [],
    editedIndex: -1,
    editedItem: {
      name: "",
    },
    defaultItem: {
      name: "",
    },
    loading: false,
    messageSnackbar: "",
    colorSnackbar: "",
    vendas: [],
    unidade_id: -1,
    data: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFormatted: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu1: false,
    menu2: false,
    atendidos: [],
    pedidos_cancelamento: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo Produto" : "Editar Produto";
    },
    unidadesSelect() {
      return [{ unidade_id: -1, name: "Todas as unidades" }, ...this.unidades];
    },
  },

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar();
    },
    data() {
      this.atualizarcancelamentosDoDia();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarcancelamentosDoDia();
      },
      deep: true,
    },
    search() {
      this.atualizarcancelamentosDoDia();
    },
  },

 
  mounted() {
    this.atualizarcancelamentosDoDia();
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getRowStyle(agendamento) {
      const agendado = this.atendidos.filter(
        (v) => v.agendamento_id == agendamento.agendamento_id
      ).length;
      return agendado ? "red text-white" : "";
    },

    remover(item) {
      const cancelamentosService = new CancelamentosService();

      const onSucess = (status) => (body) => {
        if (status == 200) {
          this.$_ACTIONS_showSnackbarMessage({
            message: body.message,
            color: "sucess",
          });
        }
      };

      const onError = () => {
        //
      };

      const onEnd = () => {
        this.tickAgenda++;
        this.atualizarcancelamentosDoDia();
      };

      cancelamentosService.update({
        onSucess,
        onError,
        onEnd,
      }, item);
    },

    atualizarProdutoCadastro() {
      this.atualizarVendas();
      this.closeDialogCadastrar();
    },

    getTurno(turno) {
      const turnos = ["Manhã", "Tarde", "Noite"];
      return turnos[parseInt(turno) - 1];
    },

    formatDateToDateBR(data) {
      if (!data) {
        return "";
      }
      return data
        .split("-")
        .reverse()
        .join("/");
    },

    atualizarcancelamentosDoDia() {
      const cancelamentosService = new CancelamentosService();
      const onSucess = (status) => (body) => {
        if (status) {
          this.pedidos_cancelamento = body.pedidos_cancelamento;
          
          this.options.totalList = body.data.pagination.num_rows;
          this.options.pageCount = body.data.pagination.last_page;
          this.options.itemsPerPage = body.data.pagination.per_page;
        }
      };

      const onError = () => {
      };

      const onEnd = () => {
        this.tickAgenda++;
      };

      const { page, itemsPerPage } = this.options;
      const filter = {};

      cancelamentosService.getAll(
        onSucess,
        onError,
        onEnd,
        page,
        itemsPerPage,
        filter
      );
    },
  },
};
</script>
