<template>
  <div>
    <card-fila
        :nome-paciente="nomePaciente"
        :hora-inicial="horaInicial"
        :hora-final="horaFinal"
        :procedimento="procedimento"
        :tipo-procedimento="tipoProcedimento"
        :cor="'rgb(154, 154, 154)'"
    />
  </div>
</template>

<script>
import CardFila from "./card_fila";
export default {
  components: { CardFila },
  props: {
    nomePaciente: {type: String, required: true},
    horaInicial: {type: String, required: true},
    horaFinal: {type: String, required: true},
    procedimento: {type: String, required: true},
    tipoProcedimento: {type: String, required: true},
  }
}
</script>