<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Horários</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="horarios"
      :options.sync="options"
      :server-items-length="totalList"
      :loading="loading"
      sort-by="nome"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG CADASTRO -->

          <v-dialog v-model="dialogCadastrarAgenda" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#981113"
                dark
                class="mb-2 mr-2"
                v-bind="attrs"
                v-on="on"
              >
                Nova agenda
              </v-btn>
            </template>
            <modal-criar-agenda
              v-if="dialogCadastrarAgenda"
              :unidades="unidades"
              @reload="atualizarHorarios"
              @close="closeDialogCadastrarAgenda"
            />
          </v-dialog>

          <v-dialog v-model="dialogCadastrar" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#981113" dark class="mb-2" v-bind="attrs" v-on="on">
                Novo horário
              </v-btn>
            </template>
            <modal-criar-horario
              v-if="dialogCadastrar"
              :unidades="unidades"
              @reload="atualizarHorarios"
              @close="closeDialogCadastrar"
            />
          </v-dialog>

          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-select
            :items="unidadesSelect"
            label="Unidade"
            item-value="unidade_id"
            item-text="name"
            @change="atualizarHorarios"
            v-model="unidade_id"
          ></v-select>
          <!-- DIALOG DE EXCLUSÃO-->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline" style="word-break: normal"
                >Tem certeza de que deseja excluir este item?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#981113" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="btn-danger"
                  text
                  style="color: #fff !important"
                  @click="deleteItemConfirm"
                  >Deletar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- DIALOG EDIÇÃO -->
          <v-dialog
            v-if="dialogEdicao"
            v-model="dialogEdicao"
            fullscreen
            persistent
          >
            <!-- <modal-edicao
                v-if="dialogEdicao"
                @close="dialogEdicao = false"
                @response="atualizarLojaPosEdicao($event)"
                :produtos="produtos"
            /> -->
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <!-- <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon> -->
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#981113" dark @click="closeDialogCadastrar">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AgendaService from "@/services/agenda_service.js";
import UnidadeService from "@/services/unidade_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import modalCriarHorario from "@/components/horarios/modal_criar_horario.vue";
import modalCriarAgenda from "@/components/horarios/modal_criar_agenda.vue";

export default {
  mixins: [Mixin],
  components: {
    // modalEdicao,
    modalCriarHorario,
    modalCriarAgenda,
  },

  computed: {
    unidadesSelect() {
      return [{ unidade_id: -1, name: "Todas as unidades" }, ...this.unidades];
    },
  },
  data: () => ({
    unidade_id: -1,
    produtos: null,
    dialogCadastrarAgenda: false,
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    unidades: [],
    // editedHorario: {},
    search: "",
    totalList: 10,
    horarios: [],
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    headers: [
      {
        text: "Turno",
        align: "start",
        sortable: true,
        value: "turno",
      },
      {
        text: "data",
        align: "start",
        sortable: false,
        value: "data",
      },
      {
        text: "unidade",
        align: "start",
        sortable: false,
        value: "unidade",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    loja: [],
    loading: false,
  }),

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarHorarios();
      },
      deep: true,
    },
    search() {
      this.atualizarHorarios();
    },
  },

  beforeMount() {
    const unidadeService = new UnidadeService();
    const onSucess = (status) => (body) => {
      if (status === 200) {
        this.unidades = body.data.unidades.map((unidade) => {
          return {
            unidade_id: unidade.id,
            name: unidade.nome_fantasia,
          };
        });
      } else {
        // console.log(body);
      }
    };

    const onError = (error) => {
      console.log(error);
    };

    const onEnd = () => {
      return; //
    };

    unidadeService.getList({ onSucess, onError, onEnd });
  },

  mounted() {
    this.atualizarHorarios();
  },

  methods: {
    atualizarLojaPosCadastro() {
      this.atualizarHorarios();
      this.closeDialogCadastrar();
    },

    atualizarLojaPosEdicao() {
      this.dialogEdicao = false;
      this.atualizarHorarios();
    },

    atualizarHorarios() {
      this.loading = true;
      const horarios = new AgendaService();
      this.horarios = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.horarios = body.data.horarios.map((horario) => {
            return {
              id: horario.agenda_id,
              turno: ["Manhã", "Tarde", "Noite"][parseInt(horario.turno) - 1],
              data: horario.data.split(" ")[0].split("-").reverse().join("/"),
              unidade: this.unidades.filter(
                (u) => u.unidade_id == horario.unidade_id
              )[0].name,
            };
          });
          this.totalList = body.data.pagination.num_rows;
        } else {
          console.log(body);
        }
      };

      const onError = (error) => {
        console.log(error);
      };

      const onEnd = () => {
        this.loading = false;
      };

      const paginationComp = {
        page: this.options.page,
        per_page: 15,
        page_count: this.options.pageCount,
        search: this.search,
        order: this.options.ordenacao,
        desc: this.options.desc,
      };

      if (this.unidade_id > 0) {
        paginationComp.unidade = this.unidade_id;
      }

      horarios.getAll({ onSucess, onError, onEnd }, paginationComp);
    },

    // editItem(item) {
    //   this.editedHorario = JSON.parse(
    //       JSON.stringify(item)
    //   )
    //   this.dialogEdicao = true
    // },

    deleteItem(item) {
      this.editedHorario = item;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      const agendaService = new AgendaService();
      agendaService.excluirAgenda(
        {
          onSucess: (status) => (body) => {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: status === 200 ? "sucess" : "error",
            });

            // status === 200 && this.loja.splice(i, 1)
          },
          onError: () => {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.atualizarHorarios();
          },
        },
        this.editedHorario.id
      );
    },

    closeDialogCadastrarAgenda() {
      this.dialogCadastrarAgenda = false;
    },

    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
