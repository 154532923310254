<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Vendas</h3>
      </div>
    </div>
    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="vendas"
      :options.sync="options"
      :server-items-length="totalList"
      :loading="loading"
      sort-by="descricao"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>

          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          />

          <v-dialog
            v-if="dialogEdicao"
            v-model="dialogEdicao"
            max-width="500px"
          >
            <modal-edicao
              v-if="dialogEdicao"
              :produto="editedProduto"
              :unidade_id="unidade_id"
              @close="dialogEdicao = false"
              @atualizar="atualizarVendas"
              @response="atualizarTipoDeProcedimentoPosEdicao($event)"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <!-- <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon> -->
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#981113" dark @click="atualizarVendas">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import VendasService from "@/services/vendas_service.js";
import modalEdicao from "@/components/estoque/modal_estoque_operacao.vue";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";


export default {
  mixins: [Mixin],
  components: { modalEdicao },
  data: () => ({
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    editedProduto: {},
    unidades: [],
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    headers: [
      { text: "Data", align: "center", value: "data", sortable: false },
      { text: "Pacotes", align: "center", value: "pacotes_nome", sortable: false },
      { text: "Preço", align: "center", value: "preco", sortable: false },
      { text: "Nome", align: "center", value: "cliente_nome", sortable: false },
      { text: "CPF", align: "center", value: "cliente_cpf", sortable: false },
      { text: "Status", align: "center", value: "status_nome", sortable: false },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    compras: [],
    editedIndex: -1,
    editedItem: {
      name: "",
    },
    defaultItem: {
      name: "", 
    },
    loading: false,
    messageSnackbar: "",
    colorSnackbar: "",
    vendas: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo Produto" : "Editar Produto";
    },
  },

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarVendas();
      },
      deep: true,
    },
    search() {
      this.atualizarVendas();
    },
  },

  mounted() {
    this.atualizarVendas();

  },

  methods: {
    atualizarProdutoCadastro() {
      this.atualizarVendas();
      this.closeDialogCadastrar();
    },
    
    atualizarVendas() {
      this.loading = true;
      this.loading = true;
      this.vendas = [];

      const vendasService = new VendasService();
      
      const status_nome = {
        'WAITING': 'aguardando',
        'PAID': 'pago',
        'DECLINED': 'negado',
      }

      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.totalList = 50;
          
          this.vendas = body.data.compras.map(compra => {
            return {
              ...compra,
              pacotes_nome: compra.pacotes.map(p => p.pacote_nome),
              status_nome: status_nome[compra.compra_status],
              data: compra.compra_data.substring(0, 10).split('-').reverse().join('/')
            }
          });
 
        } else {
          console.log(body);
        }
      };

      const onError = (error) => {
        console.log(error);
      };

      const onEnd = () => {
        this.loading = false;
      };

      vendasService.getAll(
        onSucess,
        onError,
        onEnd,
        this.options.page,
        this.options.itemsPerPage,
        this.search,
        this.options.ordenacao,
        this.options.desc
      );
    },

    editItem(item) {
      console.log(item);
      this.editedProduto = JSON.parse(JSON.stringify(item));
      console.log(this.editedProduto);
      this.dialogEdicao = true;
    },

    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

  },
};
</script>

