import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { global } from '@/config/constants.js'
import vuetify from './plugins/vuetify';
import VueMask from 'v-mask'
import VueCryptojs from 'vue-cryptojs'
import money from 'v-money'
import VueTheMask from 'vue-the-mask'
import ptBR from './locale/ptBR'

Vue.use(VueTheMask)

Vue.use(VueCryptojs)
Vue.use(VueMask);

// money filters, masks
Vue.use(money, {precision: 2})

Vue.use(vuetify, {
  lang: {
    locales: { ptBR },
    current: 'ptBR'
  }
})

function toCurrency (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0
  });
  return formatter.format(value);
}
Vue.filter('toCurrency', toCurrency);

Vue.filter('data', function (data) {
    if (!data) return '';
    let date = data.split('-');
    let dia = date[2]
    let mes = date[1]
    let ano = date[0]
    let dataAtualFormatada = dia + '/' + mes + '/' + ano
    return dataAtualFormatada;
});

Vue.config.productionTip = false
Vue.prototype.$global = global

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
