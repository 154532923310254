<template>
  <v-card>
    <v-card-title>
      <span class="headline">Editar produto</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <form class="col-12">
              <v-col cols="12">
                <v-text-field 
                  v-model="produto.produto"
                  label="Nome"
                  :error-messages="error.nome"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Preço"
                  v-mask="mask"
                  v-model.lazy="produto.preco"
                  v-money="produto.preco"
                  :error-messages="error.preco"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Indicação"
                  v-model.lazy="produto.indicacao"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Contra indicação"
                  v-model.lazy="produto.contra_indicacao"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Esquema de doses"
                  v-model.lazy="produto.esquema_doses"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Prevenção"
                  v-model.lazy="produto.prevencao"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Recomendações"
                  v-model.lazy="produto.recomendacoes"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                
                <v-autocomplete
                   v-model="produto.categorias"
                  :items="categorias"
                  :loading="loadingCategorias"
                  :search-input.sync="search_category"
                  item-text="nome"
                  item-value="categoria_id"
                  label="Categorias"
                  placeholder="Pesquise uma categoria"
                  multiple
                  :error-messages="error.produto"
                  :rules="[rules.required_arr]"
                >
                  <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remover(data.item)"
                      >
                        {{ data.item.nome }}
                      </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
          </form>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')">
        Cancelar
      </v-btn>
      <v-btn class="btn-app-primary" style="color: #fff !important;" :loading="loading" text @click="update" :disabled="allowEdition">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ProdutoService from '@/services/produtos_service.js'
import CategoriaService from '@/services/categoria_service.js'
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
});

export default {
  mixins:[Mixin],
  props: {
    produto: Object
  },
  beforeMount(){
    console.log(this.produto)
    this.loadCategorias()
  },
  data: () => ({
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    mask: currencyMask,
    nome: '',
    preco: '',
    categorias:[],
    categorias_produto:[],
    search_category:"",
    tipo_produto: '',
    loading: false,
    formCadastro:"",
    error: {
        nome: "",
        preco: "",
        tipo_produto: "",
    },
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
  }),
  methods: {

    update() {
      // console.log(this.produto)
      const produtoService = new ProdutoService()
      const produto = new Object()
      this.loading = true

      Object.assign(produto, this.produto)
      console.log(produto)
      produtoService.updateProduto({
        onSucess: status => body => {
          this.$_ACTIONS_showSnackbarMessage({
            message: body.message || this.$global.messages.internalServerError,
            color: status === 200 ? 'sucess' : 'error'
          })
        },
        onError: () => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: 'error'
          })
        },
        onEnd: () => {
          this.loading = false
          this.$emit('response', produto)
        }
      }, produto)

      
    },

    edit() {
      const produto = {}
      Object.assign(produto, this.produto)
      produto.descricao = this.descricao
      const produtoService = new ProdutoService()
      this.loading = true
      produtoService.updateDescricaoTipoProcedimento({
        onSucess: status => body => {
          this.$_ACTIONS_showSnackbarMessage({
            message: body.message || this.$global.messages.internalServerError,
            color: status === 200 ? 'sucess' : 'error'
          })
        },
        onError: () => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: 'error'
          })
        },
        onEnd: () => {
          this.loading = false
          this.$emit('response', produto)
        }
      }, produto)
    },
    loadCategorias(){
      this.loadingCategorias = true
      const categoriaService = new CategoriaService()
      this.categorias=[];
      const onSucess = (status) => (body) => {
          if (status === 200) {
          this.categorias = body.data.categorias
          } else {
            console.log(body);
          }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
            this.loadingCategorias = false;
      };
      categoriaService.getList({onSucess, onError, onEnd});
    },
    
    remover (item) {
      const index = item.categoria_id
      this.produto.categorias = this.produto.categorias.filter(p => p != index)
    },
  },
  computed: {
    allowEdition () {
      return this.descricao && this.tipo_procedimento.descricao !== this.descricao
    }
  }
}
</script>