<template>
  <v-card>
    <v-card-title>
      <span class="headline">Editar unidade</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="razao_social" label="Razão Social"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="nome_fantasia" label="Nome Fantasia"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="cnpj" label="CNPJ"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="telefone_1" label="Telefone 1"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="telefone_2" label="Telefone 2"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="cep" label="CEP"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="logradouro" label="Logradouro"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="numero" label="Número"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="bairro" label="Bairro"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="cidade" label="Cidade"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="estado" label="Estado"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="complemento" label="Complemento"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="referencia" label="Referência"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#3E682A" text @click="$emit('close')">
        Cancelar
      </v-btn>
      <v-btn color="#3E682A" :loading="loading" text @click="edit" :disabled="!allowEdition">
          Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import Mixin from "@/mixins/vuex_mixin.js";
import UnidadeService from "../../services/unidade_service";

export default {
  mixins:[Mixin],
  mounted() {
    this.id = this.unidade.id
    this.nome_fantasia = this.unidade.nome_fantasia
    this.razao_social = this.unidade.razao_social
    this.telefone_1 = this.unidade.telefone_1
    this.telefone_2 = this.unidade.telefone_2
    this.cnpj = this.unidade.cnpj
    this.cep = this.unidade.cep
    this.logradouro = this.unidade.logradouro
    this.referencia = this.unidade.referencia
    this.numero = this.unidade.numero
    this.complemento = this.unidade.complemento
    this.bairro = this.unidade.bairro
    this.cidade = this.unidade.cidade
    this.estado = this.unidade.estado
  },
  data: () => ({
    nome_fantasia: '',
    razao_social: '',
    telefone_1: '',
    telefone_2: '',
    cnpj: '',
    cep: '',
    logradouro: '',
    referencia: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    loading: false
  }),

  props: {
    unidade: {
      type: Object,
      required: true,
      validator: obj => {
        return obj.id && obj.nome_fantasia
      }
    }

  },
  methods: {
    edit() {
      const unidade = {}
      Object.assign(unidade, this.unidade)
      unidade.id = this.id
      unidade.clinica_id = 1
      unidade.nome_fantasia = this.nome_fantasia
      unidade.razao_social = this.razao_social
      unidade.telefone_1 = this.telefone_1
      unidade.telefone_2 = this.telefone_2
      unidade.cnpj = this.cnpj
      unidade.cep = this.cep
      unidade.logradouro = this.logradouro
      unidade.numero = this.numero
      unidade.bairro = this.bairro
      unidade.cidade = this.cidade
      unidade.estado = this.estado
      unidade.complemento = this.complemento
      unidade.referencia = this.referencia
      unidade.ativo = 1;
      const unidadeService = new UnidadeService()
      this.loading = true
      unidadeService.updateUnidade({
        onSucess: status => body => {
          if(status === 200){
            this.$_ACTIONS_showSnackbarMessage({
              message: body.message || "Unidade atualizada com sucesso!",
              color:'sucess'
            })
          }
        },
        onError: () => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: 'error'
          })
        },
        onEnd: () => {
          this.loading = false
          this.$emit('response', unidade)
        }
      }, unidade)
    }
  },
  computed: {
    allowEdition () {
      return true;
    }
  }
}
</script>