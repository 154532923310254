<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Produtos</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="produtos"
      :options.sync="options"
      :server-items-length="totalList"
      :loading="loading"
      sort-by="descricao"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG CADASTRO -->
          <v-dialog v-model="dialogCadastrar" fullscreen persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#981113" dark class="mb-2" v-bind="attrs" v-on="on">
                Novo Produto
              </v-btn>
            </template>
            <modal-cadastro
              v-if="dialogCadastrar"
              @close="closeDialogCadastrar"
              @response="atualizarProdutoCadastro()"
            />
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          />
          <!-- DIALOG DE EXCLUSÃO-->
          <!-- <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline" style="word-break: normal;">Tem certeza de que deseja excluir este produto?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#3E682A" text @click="closeDelete">Cancelar</v-btn>
                <v-btn class="btn-danger" text style="color: #fff !important;" @click="deleteItemConfirm">Deletar</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> -->
          <!-- DIALOG EDIÇÃO -->
          <v-dialog
            v-if="dialogEdicao"
            v-model="dialogEdicao"
            max-width="500px"
          >
            <modal-edicao
              v-if="dialogEdicao"
              :produto="editedProduto"
              @close="dialogEdicao = false"
              @response="atualizarTipoDeProcedimentoPosEdicao($event)"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#981113" dark @click="atualizarProdutos">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogDelete" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Excluir produto</span>
        </v-card-title>
        <v-card-text>
          <v-container> Deseja excluir o produto? </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="responseProdutoConfirm(false)"> Cancelar </v-btn>
          <v-btn
            color="red darken"
            text
            @click="responseProdutoConfirm(true)"
          >
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProdutosService from "@/services/produtos_service.js";
import modalCadastro from "@/components/produtos/modal_cadastro";
import modalEdicao from "@/components/produtos/modal_edicao";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  components: { modalCadastro, modalEdicao },
  data: () => ({
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    editedProduto: {},
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "produto",
      },
      { text: "Preço", align: "center", value: "preco", sortable: false },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    produtos: [],
    editedIndex: -1,
    editedItem: {
      name: "",
    },
    defaultItem: {
      name: "",
    },
    loading: false,
    messageSnackbar: "",
    colorSnackbar: "",
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo Produto" : "Editar Produto";
    },
  },

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarProdutos();
      },
      deep: true,
    },
    search() {
      this.atualizarProdutos();
    },
  },

  mounted() {
    this.atualizarProdutos();
  },

  methods: {
    atualizarProdutoCadastro() {
      this.atualizarProdutos();
      this.closeDialogCadastrar();
    },
    atualizarTipoDeProcedimentoPosEdicao() {
      this.$router.go()
    },
    responseProdutoConfirm(confirm) {
      if (confirm) {
        const produtoService = new ProdutosService();
        this.loading = true;
        this.produtos = [];
        const onSucess = (status) => (body) => {
          if (status === 200) {
            this.atualizarProdutos();
          } else {
            console.log(body);
          }
        };
        const onError = (error) => {
          console.log(error);
        };
        const onEnd = () => {
          this.loading = false;
        };

        produtoService.deleteProduto(
          { onSucess, onError, onEnd },
          this.editedProduto
        );
      }

      this.dialogDelete = false;
    },

    atualizarProdutos() {
      this.loading = true;
      const produtoService = new ProdutosService();
      this.loading = true;
      this.produtos = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.totalList = 50;
          this.produtos = body.data.produtos;
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      produtoService.getAll(
        onSucess,
        onError,
        onEnd,
        this.options.page,
        this.options.itemsPerPage,
        this.search,
        this.options.ordenacao,
        this.options.desc
      );
    },
    editItem(item) {
      console.log(item);
      this.editedProduto = JSON.parse(JSON.stringify(item));
      console.log(this.editedProduto);
      this.dialogEdicao = true;
    },

    deleteItem(item) {
      this.editedProduto = JSON.parse(JSON.stringify(item));
      this.dialogDelete = true;
    },

    //   deleteItemConfirm() {
    //     this.closeDelete()
    //     for (let i = 0, len = this.produtos.length; i < len; i++) {
    //       if (this.produtos[i].id === this.editedTipoProcedimento.id) {
    //         const tipoprocedimentoService = new TipoProcedimentoService()
    //         tipoprocedimentoService.deleteTipoProcedimento({
    //           onSucess: status => body => {
    //             this.$_ACTIONS_showSnackbarMessage({
    //               message: body.message || this.$global.messages.internalServerError,
    //               color: status === 200 ? 'sucess' : 'error'
    //             })
    //             status === 200 && this.produtos.splice(i, 1)
    //           },
    //           onError: () => {
    //             this.$_ACTIONS_showSnackbarMessage({
    //               message: this.$global.messages.internalServerError,
    //               color: 'error'
    //             })
    //           },
    //           onEnd: () => {
    //           }
    //         }, this.editedTipoProcedimento)
    //         break;
    //       }
    //     }
    //   },

    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    //   closeDelete() {
    //     this.dialogDelete = false
    //     this.$nextTick(() => {
    //       this.editedItem = Object.assign({}, this.defaultItem)
    //       this.editedIndex = -1
    //     })
    //   },
  },
};
</script>

