<template>
  <v-card>
    <v-card-title>
      <span class="headline">Cadastro de usuário</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="nome" label="Nome"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="email" label="Email"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="cpf" label="CPF" v-mask="'###.###.###-##'"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="rg" label="RG"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="nascimento" label="Data de nascimento" v-mask="'##/##/####'"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="tel_um" label="Telefone 1" v-mask="'(##) #####-####'"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="tel_dois" label="Telefone 2" v-mask="'(##) #####-####'"></v-text-field>
          </v-col>
          <v-col cols="12">
            <p>Gênero</p>
            <v-radio-group
              v-model="genero"
              column
            >
              <v-radio
                label="Masculino"
                value="M"
              ></v-radio>
              <v-radio
                label="Feminino"
                value="F"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="cep" @change="buscaCep" label="CEP" v-mask="'##.###-###'"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="logradouro" label="Logradouro"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="numero" label="Número"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="bairro" label="Bairro"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="cidade" label="Cidade"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="estado" label="Estado"></v-text-field>
          </v-col>
          <v-col cols="12">
            <p>Tipo de usuário</p>
            <v-radio-group
              v-model="tipoUsuario"
              column
            >
              <v-radio
                label="Administrador"
                value="1"
              ></v-radio>
              <v-radio
                label="Atendente"
                value="3"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#3E682A" text @click="$emit('close')">
        Cancelar
      </v-btn>
      <v-btn color="#3E682A" :loading="loading" text @click="cadastrar" >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import UsuarioService from '@/services/usuario_service.js'
import Mixin from "@/mixins/vuex_mixin.js";
import CEPService from '@/services/cep_service.js'

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export default {
  mixins:[Mixin],
  data: () => ({
    nome:"",
    email:"",
    cpf:"",
    rg:"",
    genero:"",
    nascimento:"",
    tel_um:"",
    tel_dois:"",
    cep:"",
    logradouro:"",
    numero:"",
    bairro:"",
    cidade:"",
    estado:"",
    loading: false,
    tipoUsuario: "",
  }),
  methods: {
    buscaCep() {
      const cepService= new CEPService()
      this.loading = true
      cepService.buscaCep({
        onSucess: status => body => {
          if (status === 200) {
            this.logradouro = body.logradouro
            this.estado = body.uf
            this.cidade = body.localidade
            this.bairro = body.bairro
            this.complemento = body.complemento
          }
        },
        onError: error => {
          return error
        },
        onEnd: () => {
          this.loading = false
        },
      }, this.cep.replace('-','').replace('.',''))

    }, 
    cadastrar() {

      const dataDeNascimento = this.nascimento
        .split('/')
        .reverse()
        .join('-')

      const usuario = {
        nome: this.nome,
        email: this.email,
        cpf: this.cpf,
        rg: this.rg,
        genero: this.genero,
        data_nascimento: dataDeNascimento,
        telefone_1: this.tel_um,
        telefone_2: this.tel_dois,
        cep: this.cep,
        logradouro: this.logradouro,
        numero: this.numero,
        bairro: this.bairro,
        cidade: this.cidade,
        estado: this.estado,
        perfil_id: this.tipoUsuario,
      }

      const usuarioService = new UsuarioService()
      
      if (!validateEmail(usuario.email)) {
        this.$_ACTIONS_showSnackbarMessage({
          message: 'Email inválido',
          color: 'error'
        })

        this.$emit('response')
        
        return
      }
      
      this.loading = true

      

      usuarioService.register({
        onSucess: status => body => {
          if (status === 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: body.message || "Usuario criado com sucesso!",
              color: 'sucess'
            })
            this.$emit('response')
          } else if (status === 400) {
            this.$_ACTIONS_showSnackbarMessage({
              message: body.message,
              color: 'error'
            })

            this.$emit('response')
          }
        },
        onError: error => {
          console.error(error)
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: 'error'
          })
        },
        onEnd: () => {
          this.loading = false
        },
      },
      usuario,
      )
    },
  },
}
</script>