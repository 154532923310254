<template>
    <div>
        <template>
            <!-- <v-hover v-slot="{ hover }"> -->
                <template>
                    <v-card
                        class="mx-auto rounded-circle d-flex justify-content-center align-items-center"
                    >
                        <v-img
                            class="rounded-circle"
                            :src="DADOS_CADASTRAIS_MIXIN_srcFotoUsuario"
                            width="300"
                        >
                            <!-- <v-expand-transition>
                                <div
                                    v-if="hover"
                                    class="d-flex transition-fast-in-fast-out"
                                    style="height: 100%; background-color: #3e682aa3;"
                                >
                                    <v-dialog
                                        v-model="dialog"
                                        width="500"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                color="green darken-4"
                                                v-bind="attrs"
                                                v-on="on"
                                                style="color: #fff !important; position: absolute; top: 50%; left: 50%; transform: translateX(-50%);"
                                            >
                                                <v-icon>mdi-pencil</v-icon>
                                                <span>Alterar foto de perfil</span>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title 
                                                class="headline green darken-4" 
                                                style="color: #fff !important;"
                                            >
                                                Escolha a foto
                                            </v-card-title>

                                            <v-card-text>
                                                <template>
                                                    <v-file-input
                                                        accept="image/*"
                                                        label="File input"
                                                    ></v-file-input>
                                                </template>
                                            </v-card-text>

                                            <v-divider></v-divider>

                                            <v-card-actions>
                                            <v-spacer></v-spacer>
                                                <v-btn
                                                    color="green darken-4" 
                                                    style="color: #fff !important;"
                                                    @click="dialog = false"
                                                >
                                                    Salvar
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </div>
                            </v-expand-transition> -->
                        </v-img>
                    </v-card>
                </template>
            <!-- </v-hover> -->
        </template>
    </div>
</template>

<script>
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
export default {
    mixins: [DadosCadastraisMixin],
    data: () => ({
        //Chamada do dialog de upload da imagem
        dialog: false,
    }),
}
</script>