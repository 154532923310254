<template>
  <div>  
    <div class="p-lg-3">
        <div class="row justify-content-between">
            <h3 class="mt-0 mb-2 font-weight-bold text-left">Dashboard</h3>
        </div>
    </div>
    <div v-if="$_GETTER_perfil.description === 'adm'">
      <dashboard-medico/>
    </div>
  </div>
</template>

<script>
import dashboardMedico from '@/components/dashboards/dashboard_medico/index.vue'
import VuexMixin from "@/mixins/vuex_mixin";

export default {
  mixins: [VuexMixin],
  components: {
    dashboardMedico,
  }
}
</script>
