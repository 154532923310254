// import Home from '@/views/home.vue'
import Login from '@/views/login.vue'
import EsqueceuSenha from '@/views/esqueceu-senha.vue'
// import CadastroPaciente from '@/views/cadastro_paciente.vue'
import Clientes from '@/views/clientes.vue'
import Produtos from '@/views/produtos'
import Dashboard from '@/views/dashboard.vue'
import DadosCadastrais from '@/views/dados_cadastrais.vue'
import TrocarSenha from '@/views/trocar_senha.vue'
import Categorias from '@/views/categorias.vue'
import Unidades from '@/views/unidades.vue'
import Loja from '@/views/loja.vue'
import Usuario from '@/views/usuarios.vue'
import Horarios from '@/views/horarios.vue'
import Agendamento from '@/views/agendamento.vue'
import Estoque from '@/views/estoque.vue'
import Vendas from '@/views/vendas.vue'
import GestoVacinal from '@/views/gesto_vacinal.vue'
import Cancelamentos from '@/views/cancelamentos.vue'

// AS

const routes = [
    {
      path: '/principal',
      name: 'dashboard',
      component: Dashboard,
      icon: 'fas fa-chart-bar',
      label: 'Início'
    },
    // {
    //   path: '/dashboard',
    //   name: 'dashboard',
    //   component: Dashboard,
    //   icon: 'fas fa-chart-bar',
    //   label: 'Início'
    // },
    {
      path: '/login',
      name: 'login',
      component: Login,
      icon: 'dripicons-calendar',
      label: ''
    },
    {
      path: '/esqueceu-senha',
      name: 'esqueci-minha-senha',
      component: EsqueceuSenha,
      icon: '',
      label: ''
    },
    // {
    //   path: '/novo-cadastro',
    //   name: 'cadastro-paciente',
    //   component: CadastroPaciente,
    //   icon: 'dripicons-user',
    //   label: 'Cadastro de pacientes'
    // },
    {
      path: '/clientes',
      name: 'clientes',
      component: Clientes,
      icon: 'dripicons-user',
      label: 'Clientes'
    },
    {
        path:'/loja',
        name:'loja',
        component: Loja,
        icon: 'fas fa-hospital',
        label: 'Pacotes'
    },
    {
        path:'/produtos',
        name:'produtos',
        component: Produtos,
        icon: 'fas fa-box',
        label: 'Produtos',
    },
    // {
    //     path:'/pacotes',
    //     name:'pacotes',
    //     component: Pacotes,
    //     icon: 'fas fa-boxes',
    //     label: 'Pacotes',
    // },
    {
      path: '/categorias',
      name: 'categorias',
      component: Categorias,
      icon: 'dripicons-checklist',
      label: 'Categorias'
    },
    {
      path:'/dadoscadastrais',
      name:'dadoscadastrais',
      component: DadosCadastrais,
      icon: '',
      label: 'Dados Cadastrais'
    },
    {
      path:'/trocarsenha',
      name:'trocarsenha',
      component: TrocarSenha,
      icon: '',
      label: 'Trocar senha'
    },
    {
      path:'/unidades',
      name:'unidades',
      component: Unidades,
      icon: 'fas fa-hospital',
      label: 'Unidades'
    },
    {
      path:'/usuario',
      name:'usuario',
      component: Usuario,
      icon: 'fas fa-users-cog',
      label: 'Usuários'
    },
    {
      path:'/horarios',
      name:'horarios',
      component: Horarios,
      icon: 'fas fa-calendar-plus',
      label: 'Horários'
    },
    {
      path:'/agendamento',
      name:'agendamento',
      component: Agendamento,
      icon: 'fas fa-calendar-alt',
      label: 'Agendamento'
    },
    {
      path:'/estoque',
      name:'estoque',
      component: Estoque,
      icon: 'fas fa-boxes',
      label: 'Estoque'
    },
    {
      path:'/vendas',
      name:'vendas',
      component: Vendas,
      icon: 'fas fa-list',
      label: 'Vendas'
    },
    {
      path:'/gesto_vacinal',
      name:'gesto_vacinal',
      component: GestoVacinal,
      icon: 'fas fa-syringe',
      label: 'Gesto vacinal'
    },
    {
      path:'/cancelamentos',
      name:'cancelamentos',
      component: Cancelamentos,
      icon: 'fas fa-list',
      label: 'Pedidos de cancelamento'
    },
]

export default routes