import HTTPService from '@/services/http_service.js'

export default class ClienteService extends HTTPService {
    resource = 'cliente'

    register (responseFunctions, cliente) {
        this.jsonRequest(responseFunctions, 'POST', this.resource, cliente)
    }

    update (responseFunctions, cliente) {
        this.jsonRequest(responseFunctions, 'POST', `${this.resource}/${cliente.id}`, cliente)
    }
    
    // self_register (responseFunctions, cliente) {
    //     this.request(responseFunctions, 'POST', 'auth/'+this.resource+'/auto-cadastro', cliente)
    // }
    
    // update (responseFunctions, cliente, idCliente) {
    //     this.fileRequest(responseFunctions, 'POST', this.resource+"/"+idCliente, cliente )
    // }
    
    // updateObs (responseFunctions, obs, idCliente) {
    //     this.request(responseFunctions, 'POST', this.resource+"/observacao/"+idCliente, obs )
    // }

    get (responseFunctions, idCliente) {
        const url = this.resource + '/' + idCliente
        this.request(responseFunctions, 'GET', url)
    }

    getAll (onSucess, onError, onEnd, page, per_page, filter, order, desc) {
        const responseFunctions = {
            onSucess,
            onError,
            onEnd
        }
        let queryparams = {
            page: page,
            per_page: per_page
        }
        if(filter ===''){
            if(order){
                let queryparams = {
                    page: page,
                    per_page: per_page,
                    sort: desc?'nome':'-nome'
                }
                this.request(responseFunctions, 'GET', this.resource, null, queryparams)
            }else{
                this.request(responseFunctions, 'GET', this.resource, null, queryparams)
            }
        }else{
            const byNome = filter
            if(byNome){
                let queryparams = {
                    page: page,
                    per_page: per_page,
                    nome: filter,
                }
                if(order){
                    let queryparams = {
                        page: page,
                        per_page: per_page,
                        nome: filter,
                        sort: desc?'nome':'-nome'
                    }
                    this.request(responseFunctions, 'GET', this.resource, null, queryparams)
                }else{
                    this.request(responseFunctions, 'GET', this.resource, null, queryparams)
                }
            }else{
                if (!filter) {filter = ''}
                let cpf = filter.replace(/(\.|-)/g, '');
                if(cpf.length >3 && cpf.length < 7){
                    cpf = cpf.replace(/^(\d{3})(\d)/, "$1.$2");
                }
                if(cpf.length > 6 && cpf.length < 11){
                    cpf = cpf.replace(/^(\d{3})(\d{3})(\d)/, "$1.$2.$3");
                }
                if(cpf.length >9 && cpf.length < 13){
                    cpf = cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d)/, "$1.$2.$3-$4");
                }
                let queryparams = {
                    page: page,
                    per_page: per_page,
                    cpf: cpf,
                }
                if(order){
                    let queryparams = {
                        page: page,
                        per_page: per_page,
                        cpf: cpf,
                        sort: desc?'nome':'-nome'
                    }
                    this.request(responseFunctions, 'GET', this.resource, null, queryparams)
                }else{
                   this.request(responseFunctions, 'GET', this.resource, null, queryparams)
                }
            }

        }
    }

    // isCpf(input){
    //    const eCpf = input ? input.replace(/(\.|-)/g, '') : ''
    //    return isNaN(eCpf);
    // }
    // getPacienteByName (responseFunctions, nome) {
    //     this.request(responseFunctions, 'GET', this.resource+'?nome='+nome, null)
    // }

    // getPacienteByUsuarioID (responseFunctions, idUsuario) {
    //     this.request(responseFunctions, 'GET', this.resource + '/usuario/' + idUsuario)
    // }

    // searchByNome (vm) {
    //     return nome =>{
    //         if (!nome) {
    //             vm.pacientes = [];
    //             vm.paciente=''
    //         }
    //         if (!nome || !(nome = nome.trim()) || nome.length < 3 || vm.loading_paciente) {
    //             return;
    //         }
    //         vm.loading_paciente = true;
    //         vm.pacientes = [];
    //         const requestFunctions = (
    //             {
    //                 onSucess: (status) => (body) => {
    //                     if (status === 200) {
    //                         vm.pacientes = body.data.pacientes;
    //                     } else {
    //                         vm.pacientes = [];
    //                     }
    //                 },
    //                 onError: (error) => {
    //                     console.error(error);
    //                     vm.pacientes = [];
    //                 },
    //                 onEnd: () => {
    //                     vm.loading = false;
    //                 },
    //             },
    //             this.getPacienteByName(requestFunctions, nome)
    //         );
    //     }
    // }

} 