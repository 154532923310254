<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Unidades</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="unidades"
      :options.sync="options"
      :server-items-length="totalList"
      :loading="loading"
      sort-by="nome"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG CADASTRO -->
          <v-dialog v-model="dialogCadastrar" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#981113" dark class="mb-2" v-bind="attrs" v-on="on">
                Nova Unidade
              </v-btn>
            </template>
            <modal-cadastro
              v-if="dialogCadastrar"
              @close="closeDialogCadastrar"
              @response="atualizarUnidadePosCadastro($event)"
            />
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          />


          <!-- DIALOG EDIÇÃO -->
          <v-dialog
            v-if="dialogEdicao"
            v-model="dialogEdicao"
            max-width="500px"
          >
            <modal-edicao
              v-if="dialogEdicao"
              :unidade="editedUnidade"
              @close="dialogEdicao = false"
              @response="atualizarUnidadePosEdicao($event)"
            />
          </v-dialog>
        </v-toolbar>
      </template>

      <!-- AÇÕES -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>

        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>

      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#981113" dark @click="atualizarUnidades">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Excluir unidade</span>
        <v-card-text>
          <v-container> Deseja excluir o unidade? </v-container>
        </v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="responseUnidadeConfirm(false)"> Cancelar </v-btn>
          <v-btn color="red darken" text @click="responseUnidadeConfirm(true)">
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import modalCadastro from "@/components/unidades/modal_cadastro";
import modalEdicao from "@/components/unidades/modal_edicao";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import UnidadeService from "../services/unidade_service";

export default {
  mixins: [Mixin],
  components: {
    modalCadastro,
    modalEdicao,
  },
  data: () => ({
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    editedUnidade: {},
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "nome_fantasia",
      },
      {
        text: "Cnpj",
        align: "start",
        sortable: false,
        value: "cnpj",
      },
      {
        text: "Telefone",
        align: "start",
        sortable: false,
        value: "telefone_1",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    unidades: [],
    loading: false,
  }),

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarUnidades();
      },
      deep: true,
    },
    search() {
      this.atualizarUnidades();
    },
  },

  mounted() {
    this.atualizarUnidades();
  },

  methods: {
    atualizarUnidadePosCadastro() {
      this.atualizarUnidades();
      this.closeDialogCadastrar();
    },
    responseUnidadeConfirm(confirm) {
      if (confirm) {
        const unidadeService = new UnidadeService();
        this.unidades = [];
        const onSucess = (status) => (body) => {
          if (status === 200) {
            console.log(body);
          } else {
            console.log(body);
          }
        };
        const onError = (error) => {
          console.log(error);
        };
        const onEnd = () => {
          this.loading = false;
          this.atualizarUnidades()

        };
        unidadeService.deleteUnidade(
          {onSucess,
          onError,
          onEnd,
          },
          this.editedUnidade
        );
      }

      this.dialogDelete = false;
    },
    dialogDelete(item) {
      console.log(item);
      this.dialogDelete = true;
    },
    deleteItem(item) {
      this.editedUnidade = JSON.parse(JSON.stringify(item));
      this.dialogDelete = true;
    },
    atualizarUnidadePosEdicao() {
      this.dialogEdicao = false;
      this.atualizarUnidades();
    },
    atualizarUnidades() {
      this.loading = true;
      const unidadeService = new UnidadeService();
      this.unidades = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.totalList = body.data.pagination.num_rows;
          this.unidades = body.data.unidades;
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      unidadeService.getAll(
        onSucess,
        onError,
        onEnd,
        this.options.page,
        this.options.itemsPerPage,
        this.search,
        this.options.ordenacao,
        this.options.desc
      );
    },

    editItem(item) {
      this.editedUnidade = JSON.parse(JSON.stringify(item));
      console.log(this.editedUnidade);
      this.dialogEdicao = true;
    },

    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    // closeDelete() {
    //   this.dialogDelete = false
    //   this.$nextTick(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem)
    //     this.editedIndex = -1
    //   })
    // },
  },
};
</script>
