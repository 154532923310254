import HTTPService from '@/services/http_service.js'

export default class UnidadeService extends HTTPService {
  resource = 'unidade'
  cadastrar(responseFunctions, unidade) {
    this.request(responseFunctions, 'POST', this.resource, unidade)
  }

  deleteUnidade({ onSucess, onError, onEnd }, unidade) {
    const uri = `${this.resource}/${unidade.id}`
    
    console.log(uri)
    unidade.ativo = 0;
    
    this.request({ onSucess, onError, onEnd }, 'POST', uri, unidade)
  }

  getList(responseFunctions) {
    this.request(responseFunctions, 'GET', this.resource, null)
  }

  getAll(onSucess, onError, onEnd, page, per_page, filter, order, desc) {
    const responseFunctions = {
      onSucess,
      onError,
      onEnd
    }
    let queryparams = {
      page: page,
      per_page: per_page
    }
    if (filter === '') {
      if (order) {
        let queryparams = {
          page: page,
          per_page: per_page,
          sort: desc ? 'nome' : '-nome'
        }
        this.request(responseFunctions, 'GET', this.resource, null, queryparams)
      } else {
        this.request(responseFunctions, 'GET', this.resource, null, queryparams)
      }
    } else {
      let queryparams = {
        page: page,
        per_page: per_page,
        nome: filter,
      }
      if (order) {
        let queryparams = {
          page: page,
          per_page: per_page,
          nome: filter,
          sort: desc ? 'nome' : '-nome'
        }
        this.request(responseFunctions, 'GET', this.resource, null, queryparams)
      } else {
        this.request(responseFunctions, 'GET', this.resource, null, queryparams)
      }
    }
  }

  // deleteUnidade({ onSucess, onError, onEnd }, id) {
  //   // ESTÁ SENDO UTILIZADO POST PARA EXCLUSÃO PORQUE A UMBLER INFORMOU ERRO DE CORS AO USAR O MÉTODO DELETE. CORRIGIR. 
  //   const uri = `${this.resource}/delete/${id}`
  //   this.request({ onSucess, onError, onEnd }, 'POST', uri)
  // }

  updateUnidade({ onSucess, onError, onEnd }, unidade) {
    const uri = `${this.resource}/${unidade.id}`
    console.log(uri)
    this.request({ onSucess, onError, onEnd }, 'POST', uri, unidade)
  }
}