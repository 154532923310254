<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Usuários
        </h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{'items-per-page-options': [5, 10, 15, 20]}"
      :search="search"
      :headers="headers"
      :items="itens"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialogCadastrar" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#981113" dark class="mb-2" v-bind="attrs" v-on="on">
                Novo Usuário
              </v-btn>
            </template>
            <modal-cadastro v-if="dialogCadastrar"  @close="dialogCadastrar = false" @response="atualizarListPosCadastro($event)"/>
          </v-dialog>
          
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field>

        </v-toolbar>
      </template>
      <!-- <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item, true)"> mdi mdi-eye </v-icon>
        <v-icon small class="mr-2" @click="editItem(item, false )"> fas fa-edit </v-icon>
      </template> -->
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#981113" dark @click="atualizarLista">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import modalCadastro from '@/components/usuarios/modal_cadastro'
import UsuarioService from "@/services/usuario_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins:[Mixin],
  components:{
    modalCadastro
  },
  data: () => ({
    item_detalhe: {},
    render: 0,
    dialog: false,
    dialogCadastrar: false,
    closeDialogCadastrar: false,
    loading: false,
    search: "",
    totalList:10,
    options:{
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc:false,
    },
    item: {},
    itens: [],
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "nome",
      },
      { text: "CPF", value: "cpf",  sortable: false },
      { text: "Email", value: "email",  sortable: false},
      // { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      nome: "",
      cpf: "",
      email: "",
    },
    defaultItem: {
      nome: "",
      cpf: "",
      email: "",
    },
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    options:{
      handler (){
        this.options.desc = this.options.sortDesc[0] ?? false
        this.atualizarLista();
      }, 
      deep:true
    },
    search(){
      this.atualizarLista();
    }
  },

  mounted() {
    this.atualizarLista();
  },

  methods: {
    atualizarListPosCadastro() {
      this.atualizarLista()
      this.dialogCadastrar = false
    },
    forceRender(){
      this.render+=1;
    },
    atualizarLista() {
      const usuarioService = new UsuarioService();
      this.loading = true;
      this.itens=[];
      const onSucess = (status) => (body) => {
        if (status === 200) {
         this.totalList = body.data.pagination.num_rows
         this.itens = body.data.usuarios.map((usuario) => {
            return usuario;
          });
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      usuarioService.getList(onSucess, onError, onEnd,this.options.page, this.options.itemsPerPage, this.search, this.options.ordenacao, this.options.desc);
    },

    loadinItem(id) {
      this.item_detalhe = {}
      const usuarioService = new UsuarioService();
      usuarioService.get(
        {
          onSucess: (status) => (body) => {
            if(status === 200){
              this.item_detalhe = body.data;
              this.dialog = true;
            }

          },
          onError: (error) => {
            this.messageSnackbar = error;
            this.typeMessageSnackbar = "error";
            console.log(error);
          },
          onEnd: () => {},
        },id
      );
    },

    editItem(item, bool) {
      this.editedIndex = this.clientes.indexOf(item);
      this.item = item;
      this.visualizar = bool;
      this.editedItem = Object.assign({}, item);
      this.loadingItem(this.item.id);
      
    },

    close() {
      this.dialog = false;
      this.atualizarLista();
      this.forceRender();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.dialogCadastro = false;
      if (this.editedIndex > -1) {
        Object.assign(this.itens[this.editedIndex], this.editedItem);
      } else {
        this.itens.push(this.editedItem);
      }
      this.item_detalhe = false
      this.options.ordenacao = false
      this.options.desc = false
      this.close();
      this.atualizarLista();
      this.forceRender();
    },
  },
};
</script>