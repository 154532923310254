import HTTPService from '@/services/http_service.js'

export default class DashboardService extends HTTPService {
    resource = 'dashboard'
    getDashboarMedico ({onSucess, onError, onEnd}, idMedico) {
        const url = `${this.resource}/medico/${idMedico}`
        this.request({onSucess, onError, onEnd},'GET', url);
    }

    getDashboardPaciente ({onSucess, onError, onEnd}, idPaciente) {
        const url = `${this.resource}/paciente/${idPaciente}`
        this.request({onSucess, onError, onEnd}, 'GET', url);
    }
}