"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  close: 'Close',
  dataIterator: {
    pageText: '{0}-{1} de {2}',
    noResultsText: 'Nenhum dado encontrado',
    loadingText: 'Carregando itens...'
  },
  dataTable: {
    itemsPerPageText: 'Linhas por página:',
    ariaLabel: {
      sortDescending: ': Ordenado decrescente. Ative para remover a ordenação.',
      sortAscending: ': Ordenado crescente. Ative para ordenar decrescente.',
      sortNone: ': Não ordenado. Ative para ordenar crescente.'
    },
    sortBy: 'Ordenar por'
  },
  dataFooter: {
    itemsPerPageText: 'Itens por página:',
    itemsPerPageAll: 'Todos',
    nextPage: 'Próxima página',
    prevPage: 'Página anterior',
    firstPage: 'Página primeira',
    lastPage: 'Página última'
  },
  datePicker: {
    itemsSelected: '{0} selecionados'
  },
  noDataText: 'Não há dados disponíveis',
  carousel: {
    prev: 'Visão anterior',
    next: 'Próxima visão'
  },
  calendar: {
    moreEvents: 'Mais {0}'
  },
  fileInput: {
    counter: '{0} files',
    counterSize: '{0} files ({1} in total)'
  }
};
exports.default = _default;