const global = {
    api: {
        // baseURL: 'http://api.nestor.prolins.com.br'
        // baseURL: 'http://api.nestorvasconcelos.com.br'
        // baseURL: 'http://localhost:8080'
        // baseURL: 'http://192.168.1.31:8080'
        baseURL: 'http://api.saocarlosvacinas.prolins.com.br'
        // baseURL: 'https://8d68-189-36-201-51.ngrok.io'
    },
    consultas: {
        tempoMinimo: 15,    
        idTipoProcedimentoConsulta:5
    },
    tiposProcedimentos: {
      consulta: 5,
      cirurgia: 6,
      compromissoPessoal: 7,

    },
    utils: {
        diasDaSemana: [
            { value: 0, label: 'Domingo' } ,
            { value: 1, label: 'Segunda-feira' },
            { value: 2, label: 'Terça-feira' },
            { value: 3, label: 'Quarta-feira' },
            { value: 4, label: 'Quinta-feira' },
            { value: 5, label: 'Sexta-feira' },
            { value: 6, label: 'Sábado' } 
        ]
    },
    profiles: [
        { id: 1, description: 'admin' },
        { id: 3, description: 'atendente' },
    ],
    snackbar: {
        colors: {
            sucess: '#3E682A',
            error: 'error',
            warning: '#FFCA28'
        }
    },
    messages: {
        internalServerError: 'Ocorreu um erro durante o processamento da sua solicitação. Tente novamente dentro de alguns minutos. Se o serviço continuar sem funcionar, contate o administrador do sistema.'
    }
}
export { global }
