<template>
  <div class="row">
    <fila-atual v-if="compromissos.length"
        :compromissos="compromissos"
        @iniciar-consulta="iniciarConsulta($event)"
        @paciente-ausente="ativarModalAtrasoPaciente"
        @paciente="paciente = $event"
    />
    <clientes-atrasados
        :compromissos="compromissos"
        @iniciar-consulta="iniciarConsulta"
        @remover="removerPacienteDaListaDeAtrasados($event)"
    />
    <compromissos :compromissos="compromissos"/>
    <v-dialog
        v-model="modalConsulta"
        fullscreen
        scrollable
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
    >
        <modal-iniciar-consulta :paciente="paciente" :info="info" @close="cancelarConsulta" @finalizarConsulta="finalizarConsulta($event)" />
    </v-dialog>
    <dialog-confirmacao-atraso
        :enabled="modalAtrasoPaciente"
        @cancel="modalAtrasoPaciente = false"
        @confirm="moverPacienteParaListaDeAtrasados"
    />
  </div>
</template>

<script>
import FilaAtual from './fila_atual'
import ClientesAtrasados from "./clientes_atrasados";
import Compromissos from './compromissos'
import DialogConfirmacaoAtraso from '@/components/dashboards/dashboard_medico/fila_atual/dialog_confirmacao_atraso.vue'
import DashboardService from "@/services/dashboard_service";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [VuexSnackbarMixin],
  beforeMount() {
    this.atualizarCompromissos()
  },
  components: {
    FilaAtual,
    ClientesAtrasados,
    Compromissos,
    DialogConfirmacaoAtraso
  },

  data: () => ({
    modalConsulta: false,
    modalAtrasoPaciente: false,
    paciente: {},
    info: {},
    compromissos: [],
    loading: false,
    indexCompromissoSelecionado: null,
    idAgendamentoSelecionado: null,
  }),
  methods: {
    atualizarCompromissos () {
      this.loading = true
      const dashboardService = new DashboardService ()
      dashboardService.getDashboarMedico({
        onSucess: status => body => {
          if (status === 200) {
            this.compromissos = body.data.map(agenda => (
              {
                idAgendamento: agenda.id_agendamento,
                idConsulta: agenda.id_consulta,
                emAtraso: !!agenda.atrasado,
                data: agenda.data,
                horarioInicio: agenda.hora_inicio,
                horarioFinal: agenda.hora_final,
                nomePaciente: agenda.nome,
                procedimento: agenda.procedimento,
                tipoProcedimento: agenda.tipo_procedimento,
                idProcedimento: +agenda.id_procedimento,
                idTipoProcedimento: agenda.id_tipo_procedimento
              }
          ))
          }
        },
        onError: error => {
          console.error(error)
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: 'error'
          })
        },
        onEnd: () => {
          this.loading = false
        }
      }, 1)
    },
    iniciarConsulta (e) {
      console.log(e)
      this.info = e
      this.modalConsulta = true
    },
    cancelarConsulta () {
     this.modalConsulta = false 
     this.info = {}
     this.compromissos = []
     this.atualizarCompromissos()
    },
    finalizarConsulta (e) {
      this.modalConsulta = false
      this.$_ACTIONS_showSnackbarMessage({
        message: e.msg,
        color: e.cor,
      });
       this.$router.replace({ name: 'prontuarios', params: { search: e.nome } });
       this.info = {}
       this.compromissos = []

    },
    ativarModalAtrasoPaciente (event) {
      this.idAgendamentoSelecionado = event.idAgendamentoSelecionado
      this.modalAtrasoPaciente = true
    },
    moverPacienteParaListaDeAtrasados () {
      for (let i = 0, len = this.compromissos.length; i < len; i++) {
        let compromisso = this.compromissos[i]
        if (compromisso.idAgendamento === this.idAgendamentoSelecionado) {
          compromisso.emAtraso = true
          this.idAgendamentoSelecionado = null
          break;
        }
      }
      this.modalAtrasoPaciente = false
    },
    removerPacienteDaListaDeAtrasados (event) {
      for (let i = 0, len = this.compromissos.length; i < len; i++) {
        let compromisso = this.compromissos[i]
        if (compromisso.idAgendamento === event.idAgendamento) {
          compromisso.emAtraso = false
          break;
        }
      }
    }
  }
};
</script>

<style scoped>
.titulo-cards-fila span {
  margin-right: 1rem;
  font-size: 1rem;
}

.titulo-cards-fila hr {
  display: block;
  width: 100%;
  color: #fff;
  margin: 0;
}
</style>