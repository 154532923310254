<template>
  <div @click="$emit('unselect')" style="cursor: pointer">
    <card-fila
        :nome-paciente="nomePaciente"
        :hora-inicial="horaInicial"
        :hora-final="horaFinal"
        :procedimento="procedimento"
        :tipo-procedimento="tipoProcedimento"
        :cor="cor"
        @mouseover="cor = corHover"
        @mouseout="cor = corHoverOut"
    />
  </div>
</template>

<script>
import CardFila from "./card_fila";
export default {
  components: { CardFila },
  data: () => ({
    cor: 'rgb(62, 104, 42)',
    corHover: 'rgb(62, 104, 42)',
    corHoverOut: 'rgb(62, 104, 42)',
  }),
  props: {
    nomePaciente: {type: String, required: true},
    horaInicial: {type: String, required: true},
    horaFinal: {type: String, required: true},
    procedimento: {type: String, required: true},
    tipoProcedimento: {type: String, required: true},
  }
}
</script>