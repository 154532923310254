<template>
  <v-card>
    <v-card-title>
      <span class="headline">Cadastro de unidade</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="razao_social" label="Razão Social"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="nome_fantasia" label="Nome Fantasia"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="cnpj" label="CNPJ" v-mask="'##.###.###/####-##'"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="tel_um" label="Telefone 1" v-mask="'(##) #####-####'"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="tel_dois" label="Telefone 2" v-mask="'(##) #####-####'"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="cep" @change="buscaCep" label="CEP" v-mask="'##.###-###'"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="logradouro" label="Logradouro"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="numero" label="Número"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="bairro" label="Bairro"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="cidade" label="Cidade"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="uf" label="UF"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="complemento" label="Complemento"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="referencia" label="Referência"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#3E682A" text @click="$emit('close')">
        Cancelar
      </v-btn>
      <v-btn color="#3E682A" :loading="loading" text @click="cadastrar" >
          Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import UnidadeService from '@/services/unidade_service.js'
import CEPService from '@/services/cep_service.js'
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins:[Mixin],
  data: () => ({
    razao_social: '',
    nome_fantasia: '',
    cnpj: '',
    tel_um: '',
    tel_dois: '',
    cep: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cidade: '',
    uf: '',
    complemento: '',
    referencia: '',
    loading: false,
  }),
  methods: {
    buscaCep() {
      const cepService= new CEPService()
      this.loading = true
      cepService.buscaCep({
        onSucess: status => body => {
          if (status === 200) {
            this.logradouro = body.logradouro
            this.uf = body.uf
            this.cidade = body.localidade
            this.bairro = body.bairro
            this.complemento = body.complemento
          }
        },
        onError: error => {
          return error
        },
        onEnd: () => {
          this.loading = false
        },
      }, this.cep.replace('-','').replace('.',''))

    }, 
    cadastrar() {
      const unidade = {
        clinica_id: 1,
        razao_social: this.razao_social,
        nome_fantasia: this.nome_fantasia,
        cnpj: this.cnpj,
        telefone_1: this.tel_um,
        telefone_2: this.tel_dois,
        cep: this.cep,
        logradouro: this.logradouro,
        numero: this.numero,
        bairro: this.bairro,
        cidade: this.cidade,
        estado: this.uf,
        complemento: this.complemento,
        referencia: this.referencia,
      }
      const unidadeService = new UnidadeService()
      this.loading = true
      unidadeService.cadastrar({
        onSucess: status => body => {
          if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message || "Unidade criada com sucesso!",
              color: 'sucess'
            })
            this.$emit('response')
          }
        },
        onError: error => {
          console.error(error)
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: 'error'
          })
        },
        onEnd: () => {
          this.loading = false
        },
      },
      unidade,
      )
    },
  },
}
</script>