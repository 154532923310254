<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Pacotes</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="loja"
      :options.sync="options"
      :server-items-length="totalList"
      :loading="loading"
      sort-by="nome"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG CADASTRO -->

          <v-dialog v-model="dialogCadastrar" max-width="1000px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#981113" dark class="mb-2" v-bind="attrs" v-on="on">
                Novo item da loja
              </v-btn>
            </template>
            <modal-cadastro
              v-if="dialogCadastrar"
              @close="closeDialogCadastrar"
              :produtos="produtos"
              @response="atualizarLojaPosCadastro($event)"
            />
          </v-dialog>

          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          />
          <!-- DIALOG DE EXCLUSÃO-->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline" style="word-break: normal"
                >Tem certeza de que deseja excluir este item?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#981113" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="btn-danger"
                  text
                  style="color: #fff !important"
                  @click="deleteItemConfirm"
                  >Deletar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- DIALOG EDIÇÃO -->
          <v-dialog
            v-if="dialogEdicao"
            v-model="dialogEdicao"
            fullscreen
            persistent
          >
            <modal-edicao
              v-if="dialogEdicao"
              :loja="editedLoja"
              @close="dialogEdicao = false"
              @response="atualizarLojaPosEdicao($event)"
              :produtos="produtos"
            />
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#981113" dark @click="closeDialogCadastrar">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ProdutoService from "@/services/produtos_service.js";

import LojaService from "@/services/loja_service.js";
import modalCadastro from "@/components/loja/modal_cadastro";
import modalEdicao from "@/components/loja/modal_edicao";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  components: {
    modalCadastro,
    modalEdicao,
  },
  data: () => ({
    produtos: null,
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    editedLoja: {},
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "nome",
      },
      {
        text: "Preço",
        align: "start",
        sortable: false,
        value: "preco",
      },
      {
        text: "Status",
        align: "start",
        sortable: false,
        value: "ativo",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    loja: [],
    loading: false,
  }),

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarLojas();
      },
      deep: true,
    },
    search() {
      this.atualizarLojas();
    },
  },

  mounted() {
    this.atualizarLojas();
    this.loadProdutos();
  },

  methods: {
    loadProdutos() {
      this.loadingProdutos = true;
      const produtoService = new ProdutoService();
      this.produtos = [];

      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.produtos = body.data.produtos;
        } else {
          // console.log(body);
        }
      };

      const onError = (error) => {
        console.log(error);
      };

      const onEnd = () => {
        this.loadingProdutos = false;
      };
      produtoService.getList({ onSucess, onError, onEnd });
    },

    atualizarLojaPosCadastro() {
      this.atualizarLojas();
      this.closeDialogCadastrar();
    },

    atualizarLojaPosEdicao() {
      this.dialogEdicao = false;
      this.atualizarLojas();
    },
    
    atualizarLojas() {
      this.loading = true;
      const lojaService = new LojaService();
      this.loja = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.totalList = body.data.pagination.num_rows;
          this.loja = body.data.loja;
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      lojaService.getAll(
        onSucess,
        onError,
        onEnd,
        this.options.page,
        this.options.itemsPerPage,
        this.search,
        this.options.ordenacao,
        this.options.desc
      );
    },

    editItem(item) {
      this.editedLoja = JSON.parse(JSON.stringify(item));
      this.dialogEdicao = true;
    },

    deleteItem(item) {
      this.editedLoja = JSON.parse(JSON.stringify(item));
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();
      for (let i = 0; i < this.loja.length; i++) {
        if (this.loja[i].loja_id === this.editedLoja.loja_id) {
          const lojaService = new LojaService();
          lojaService.deleteLoja(
            {
              onSucess: (status) => (body) => {
                this.$_ACTIONS_showSnackbarMessage({
                  message:
                    body.message || this.$global.messages.internalServerError,
                  color: status === 200 ? "sucess" : "error",
                });
                status === 200 && this.loja.splice(i, 1);
              },
              onError: () => {
                this.$_ACTIONS_showSnackbarMessage({
                  message: this.$global.messages.internalServerError,
                  color: "error",
                });
              },
              onEnd: () => {},
            },
            this.editedLoja
          );
          break;
        }
      }
    },

    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
